import { SyntheticEvent } from 'react';
import { isNumber } from 'lodash';
import { getIn } from 'formik';

import {
  CandidateField,
  AutocompleteTextfield,
  HiddenBlockWrapper,
} from '@components';
import { Box, TextField, Typography, createFilterOptions } from '@mui/material';

import { useAppSelector } from '@redux/hooks';
import { optionsSelectors } from '@redux/options';

import { SubmissionFormik } from '@types';
import { CandidateFieldsLabels, SubmissionSeniorities } from '@constants';

export const SubmissionProfileStep: React.FC<{
  formik: SubmissionFormik;
  onHideFieldToggle: (field: string, isHidden: boolean) => void;
}> = ({ formik, onHideFieldToggle }) => {
  const { data: candidateFieldOptions } = useAppSelector(
    optionsSelectors.getCandidateFieldsOptions,
  );

  const seniorityError =
    (!!getIn(formik.touched, 'profile.seniority') || !!formik.submitCount) &&
    getIn(formik.errors, 'profile.seniority')
      ? getIn(formik.errors, 'profile.seniority')
      : null;
  const primarySkillsError =
    (!!getIn(formik.touched, 'profile.primarySkills') ||
      !!formik.submitCount) &&
    getIn(formik.errors, 'profile.primarySkills')
      ? getIn(formik.errors, 'profile.primarySkills')
      : null;
  const secondarySkillsError =
    (!!getIn(formik.touched, 'profile.secondarySkills') ||
      !!formik.submitCount) &&
    getIn(formik.errors, 'profile.secondarySkills')
      ? getIn(formik.errors, 'profile.secondarySkills')
      : null;

  return (
    <Box display="flex" flexDirection="column" gap={2} py={2}>
      <Typography variant="h3">Adjust candidate details</Typography>
      <HiddenBlockWrapper
        hidden={formik.values.hiddenFields.includes('profile.email')}
        onHideToggle={(isHidden) =>
          onHideFieldToggle('profile.email', isHidden)
        }
      >
        <CandidateField
          field="Email"
          value={formik.values.profile.email}
          touched={
            !!getIn(formik.touched, 'profile.email') || !!formik.submitCount
          }
          error={getIn(formik.errors, 'profile.email')}
          onChange={(value) =>
            formik.setFieldValue('profile.email', value || null)
          }
          onBlur={() => formik.setFieldTouched('profile.email')}
          disabled={formik.values.hiddenFields.includes('profile.email')}
        />
      </HiddenBlockWrapper>
      <HiddenBlockWrapper
        hidden={formik.values.hiddenFields.includes('profile.firstName')}
        onHideToggle={(isHidden) =>
          onHideFieldToggle('profile.firstName', isHidden)
        }
        disabled
      >
        <CandidateField
          field="First_Name"
          value={formik.values.profile.firstName}
          required
          disabled={formik.values.hiddenFields.includes('profile.firstName')}
          touched={
            !!getIn(formik.touched, 'profile.firstName') || !!formik.submitCount
          }
          error={getIn(formik.errors, 'profile.firstName')}
          onChange={(value) =>
            formik.setFieldValue('profile.firstName', value || null)
          }
          onBlur={() => formik.setFieldTouched('profile.firstName')}
        />
      </HiddenBlockWrapper>
      <HiddenBlockWrapper
        hidden={formik.values.hiddenFields.includes('profile.lastName')}
        onHideToggle={(isHidden) =>
          onHideFieldToggle('profile.lastName', isHidden)
        }
        disabled
      >
        <CandidateField
          field="Last_Name"
          value={formik.values.profile.lastName}
          required
          disabled={formik.values.hiddenFields.includes('profile.lastName')}
          touched={
            !!getIn(formik.touched, 'profile.lastName') || !!formik.submitCount
          }
          error={getIn(formik.errors, 'profile.lastName')}
          onChange={(value) =>
            formik.setFieldValue('profile.lastName', value || null)
          }
          onBlur={() => formik.setFieldTouched('profile.lastName')}
        />
      </HiddenBlockWrapper>
      <HiddenBlockWrapper
        hidden={formik.values.hiddenFields.includes('profile.country')}
        onHideToggle={(isHidden) =>
          onHideFieldToggle('profile.country', isHidden)
        }
        disabled
      >
        <CandidateField
          field="Location_Country"
          value={formik.values.profile.country}
          required
          disabled={formik.values.hiddenFields.includes('profile.country')}
          touched={
            !!getIn(formik.touched, 'profile.country') || !!formik.submitCount
          }
          error={getIn(formik.errors, 'profile.country')}
          onChange={(value) =>
            formik.setFieldValue('profile.country', value || null)
          }
          onBlur={() => formik.setFieldTouched('profile.country')}
        />
      </HiddenBlockWrapper>
      <HiddenBlockWrapper
        hidden={formik.values.hiddenFields.includes('profile.city')}
        onHideToggle={(isHidden) => onHideFieldToggle('profile.city', isHidden)}
        disabled
      >
        <AutocompleteTextfield
          label="Location city"
          value={formik.values.profile.city}
          required
          fullWidth
          freeSolo
          filterSelectedOptions
          clearOnBlur
          selectOnFocus
          options={candidateFieldOptions?.Location_City}
          filterOptions={(options, params) => {
            const filtered = createFilterOptions<string>()(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option);
            if (inputValue !== '' && !isExisting) {
              filtered.push(`Add "${inputValue}"`);
            }

            return filtered;
          }}
          onChange={(e: SyntheticEvent, newValue: string | null) => {
            const newSkillNameValue = newValue?.includes('Add "')
              ? /Add "(.*)"/gi.exec(newValue)?.[1]
              : newValue;

            formik.setFieldValue('profile.city', newSkillNameValue);
          }}
          onBlur={() => formik.setFieldTouched('profile.city')}
          helperText={
            !!getIn(formik.touched, 'profile.city') || !!formik.submitCount
              ? getIn(formik.errors, 'profile.city')
              : undefined
          }
          error={
            (!!getIn(formik.touched, 'profile.city') || !!formik.submitCount) &&
            !!getIn(formik.errors, 'profile.city')
          }
        />
      </HiddenBlockWrapper>
      <HiddenBlockWrapper
        hidden={formik.values.hiddenFields.includes('profile.englishLevel')}
        onHideToggle={(isHidden) =>
          onHideFieldToggle('profile.englishLevel', isHidden)
        }
        disabled
      >
        <CandidateField
          field="English_level"
          value={formik.values.profile.englishLevel}
          required
          disabled={formik.values.hiddenFields.includes('profile.englishLevel')}
          touched={
            !!getIn(formik.touched, 'profile.englishLevel') ||
            !!formik.submitCount
          }
          error={getIn(formik.errors, 'profile.englishLevel')}
          onChange={(value) =>
            formik.setFieldValue('profile.englishLevel', value || null)
          }
          onBlur={() => formik.setFieldTouched('profile.englishLevel')}
        />
      </HiddenBlockWrapper>
      <HiddenBlockWrapper
        hidden={formik.values.hiddenFields.includes('profile.specialization')}
        onHideToggle={(isHidden) =>
          onHideFieldToggle('profile.specialization', isHidden)
        }
      >
        <CandidateField
          field="Dev_-_QA-AQA"
          value={formik.values.profile.specialization}
          disabled={formik.values.hiddenFields.includes(
            'profile.specialization',
          )}
          touched={
            !!getIn(formik.touched, 'profile.specialization') ||
            !!formik.submitCount
          }
          error={getIn(formik.errors, 'profile.specialization')}
          onChange={(value) =>
            formik.setFieldValue('profile.specialization', value || null)
          }
          onBlur={() => formik.setFieldTouched('profile.specialization')}
        />
      </HiddenBlockWrapper>
      <HiddenBlockWrapper
        hidden={formik.values.hiddenFields.includes('profile.technicalFlow')}
        onHideToggle={(isHidden) =>
          onHideFieldToggle('profile.technicalFlow', isHidden)
        }
        disabled
      >
        <CandidateField
          field="Technical_Flow"
          value={formik.values.profile.technicalFlow}
          required
          disabled={formik.values.hiddenFields.includes(
            'profile.technicalFlow',
          )}
          touched={
            !!getIn(formik.touched, 'profile.technicalFlow') ||
            !!formik.submitCount
          }
          error={getIn(formik.errors, 'profile.technicalFlow')}
          onChange={(value) =>
            formik.setFieldValue('profile.technicalFlow', value || null)
          }
          onBlur={() => formik.setFieldTouched('profile.technicalFlow')}
        />
      </HiddenBlockWrapper>
      <HiddenBlockWrapper
        hidden={formik.values.hiddenFields.includes('profile.primarySkills')}
        onHideToggle={(isHidden) =>
          onHideFieldToggle('profile.primarySkills', isHidden)
        }
        disabled
      >
        <AutocompleteTextfield
          name="Primary_Skill_Set"
          label={CandidateFieldsLabels.Primary_Skill_Set}
          value={formik.values.profile.primarySkills || []}
          options={candidateFieldOptions?.Primary_Skill_Set}
          required
          disabled={formik.values.hiddenFields.includes(
            'profile.primarySkills',
          )}
          onBlur={() => formik.setFieldTouched('profile.primarySkills')}
          fullWidth
          multiple
          freeSolo
          filterSelectedOptions
          clearOnBlur
          selectOnFocus
          error={primarySkillsError || undefined}
          helperText={primarySkillsError || undefined}
          filterOptions={(options, params) => {
            const filtered = createFilterOptions<string>()(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option);
            if (inputValue !== '' && !isExisting) {
              filtered.push(`Add "${inputValue}"`);
            }

            return filtered;
          }}
          onChange={(e: SyntheticEvent, newValue: string[] | null) => {
            const newSkillNameValue = newValue?.map((value) =>
              value?.includes('Add "')
                ? /Add "(.*)"/gi.exec(value)?.[1]
                : value,
            );

            formik.setFieldValue(
              'profile.primarySkills',
              newSkillNameValue?.length ? newSkillNameValue : null,
            );
          }}
        />
      </HiddenBlockWrapper>
      <HiddenBlockWrapper
        hidden={formik.values.hiddenFields.includes('profile.secondarySkills')}
        onHideToggle={(isHidden) =>
          onHideFieldToggle('profile.secondarySkills', isHidden)
        }
      >
        <AutocompleteTextfield
          name="Secondary_Skill_Set"
          label={CandidateFieldsLabels.Secondary_Skill_Set}
          value={formik.values.profile.secondarySkills || []}
          options={candidateFieldOptions?.Secondary_Skill_Set}
          required
          disabled={formik.values.hiddenFields.includes(
            'profile.secondarySkills',
          )}
          onBlur={() => formik.setFieldTouched('profile.secondarySkills')}
          fullWidth
          multiple
          freeSolo
          filterSelectedOptions
          clearOnBlur
          selectOnFocus
          error={secondarySkillsError || undefined}
          helperText={secondarySkillsError || undefined}
          filterOptions={(options, params) => {
            const filtered = createFilterOptions<string>()(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option);
            if (inputValue !== '' && !isExisting) {
              filtered.push(`Add "${inputValue}"`);
            }

            return filtered;
          }}
          onChange={(e: SyntheticEvent, newValue: string[] | null) => {
            const newSkillNameValue = newValue?.map((value) =>
              value?.includes('Add "')
                ? /Add "(.*)"/gi.exec(value)?.[1]
                : value,
            );

            formik.setFieldValue(
              'profile.secondarySkills',
              newSkillNameValue?.length ? newSkillNameValue : null,
            );
          }}
        />
      </HiddenBlockWrapper>
      <HiddenBlockWrapper
        hidden={formik.values.hiddenFields.includes('profile.experience')}
        onHideToggle={(isHidden) =>
          onHideFieldToggle('profile.experience', isHidden)
        }
        disabled
      >
        <TextField
          name="profile.experience"
          label="Experience"
          placeholder="Candidate's experience"
          variant="outlined"
          type="number"
          fullWidth
          value={
            isNumber(formik.values.profile.experience)
              ? formik.values.profile.experience
              : ''
          }
          required
          disabled={formik.values.hiddenFields.includes('profile.experience')}
          onChange={(e) =>
            formik.setFieldValue(
              'profile.experience',
              e.target.value ? parseInt(e.target.value) : null,
            )
          }
          onBlur={formik.handleBlur}
          helperText={
            !!getIn(formik.touched, 'profile.experience') ||
            !!formik.submitCount
              ? getIn(formik.errors, 'profile.experience')
              : undefined
          }
          error={
            (!!getIn(formik.touched, 'profile.experience') ||
              !!formik.submitCount) &&
            !!getIn(formik.errors, 'profile.experience')
          }
        />
      </HiddenBlockWrapper>
      <HiddenBlockWrapper
        hidden={formik.values.hiddenFields.includes('profile.seniority')}
        onHideToggle={(isHidden) =>
          onHideFieldToggle('profile.seniority', isHidden)
        }
        disabled
      >
        <AutocompleteTextfield
          name="Seniority_Level"
          label={CandidateFieldsLabels.Seniority_Level}
          options={SubmissionSeniorities}
          value={formik.values.profile.seniority}
          required
          onChange={(_, value) =>
            formik.setFieldValue('profile.seniority', value || null)
          }
          onBlur={() => formik.setFieldTouched('profile.seniority')}
          fullWidth
          error={!!seniorityError}
          helperText={seniorityError ? seniorityError : undefined}
          disabled={formik.values.hiddenFields.includes('profile.seniority')}
        />
      </HiddenBlockWrapper>
      <HiddenBlockWrapper
        hidden={formik.values.hiddenFields.includes('profile.noticePeriod')}
        onHideToggle={(isHidden) =>
          onHideFieldToggle('profile.noticePeriod', isHidden)
        }
        disabled
      >
        <CandidateField
          field="Notice_period_from_Offer"
          value={formik.values.profile.noticePeriod}
          required
          disabled={formik.values.hiddenFields.includes('profile.noticePeriod')}
          touched={
            !!getIn(formik.touched, 'profile.noticePeriod') ||
            !!formik.submitCount
          }
          error={getIn(formik.errors, 'profile.noticePeriod')}
          onChange={(value) =>
            formik.setFieldValue('profile.noticePeriod', value || null)
          }
          onBlur={() => formik.setFieldTouched('profile.noticePeriod')}
        />
      </HiddenBlockWrapper>
    </Box>
  );
};
