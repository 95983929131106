import { Box, Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import PhotoSizeSelectSmallOutlinedIcon from '@mui/icons-material/PhotoSizeSelectSmallOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import { IQuestionVI } from '@types';
import { VIQuestionsList } from '@components';

export const VideoInterviewQuestionPreview: React.FC<{
  videoInterviewQuestionsList: IQuestionVI[] | null;
  specialization: string | null;
  label: string;
  isCustom?: boolean;
}> = ({ label, videoInterviewQuestionsList, specialization, isCustom }) => {
  const [isQuestionVisible, setIsQuestionVisible] = useState(false);

  const toggleQuestionVisibility = () => {
    setIsQuestionVisible((prev) => !prev);
  };
  return (
    <>
      <Stack
        sx={{
          padding: '0.5rem 0.25rem 0.75rem',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            width: {
              md: '7.5rem',
            },
            marginTop: { sm: '0.25rem' },
            display: 'flex',
            alignItems: 'flex-start',
            gap: '5px',
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {label}
          </Typography>
        </Box>

        <Stack
          flex={1}
          direction="row"
          sx={{
            overflowWrap: 'anywhere',
            alignItems: 'flex-start',
            marginTop: { sm: '0.25rem' },
          }}
        >
          {!videoInterviewQuestionsList ? (
            <Typography variant="body1" color={'text.secondary'}>
              —
            </Typography>
          ) : (
            <Typography variant="body1" color={'text.primary'}>
              {specialization}
              {isCustom ? ' (custom) ' : ''}
            </Typography>
          )}
        </Stack>

        {videoInterviewQuestionsList && (
          <Button
            variant="text"
            startIcon={
              isQuestionVisible ? (
                <PhotoSizeSelectSmallOutlinedIcon fontSize="small" />
              ) : (
                <ListOutlinedIcon fontSize="small" />
              )
            }
            sx={{
              justifyContent: 'end',
              color: 'text.link',
              fontWeight: 400,
              textTransform: 'unset',
              fontSize: '0.8125rem',
              ml: -1,
              sm: { mt: 1 },
            }}
            onClick={toggleQuestionVisibility}
          >
            {isQuestionVisible ? 'Hide Questions' : 'Show Questions'}
          </Button>
        )}
      </Stack>

      <Stack
        sx={{
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
          '&:last-child': {
            borderBottom: 'none',
          },
          gap: {
            xs: '0',
            sm: '0.5rem',
          },
        }}
      >
        {isQuestionVisible && (
          <VIQuestionsList questionsList={videoInterviewQuestionsList || []} />
        )}
      </Stack>
    </>
  );
};
