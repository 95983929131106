import { SyntheticEvent } from 'react';
import dayjs from 'dayjs';
import { format } from 'date-fns';
import { isNumber } from 'lodash';

import { Box, createFilterOptions, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AutocompleteTextfield, TextButton } from '@components';

import {
  EntityReportColumnType,
  EntityReportFilterOperator,
  EntityReportFilterOperatorWithoutValue,
} from '@constants';
import { isTruthy } from '@utils';

export const EntityReportFormFilterColumnValue: React.FC<{
  columnType: EntityReportColumnType | null;
  operator: EntityReportFilterOperator | null;
  value: Array<string | number | null> | null;
  options: string[];
  onChange: (value: Array<string | number | null> | null) => void;
}> = ({ columnType, operator, value, options, onChange }) => {
  if (
    !columnType ||
    !operator ||
    EntityReportFilterOperatorWithoutValue.includes(operator)
  )
    return null;

  if (columnType === EntityReportColumnType.Number) {
    return (
      <TextField
        label="Value"
        variant="outlined"
        type="number"
        fullWidth
        required
        value={isNumber(value?.[0]) ? value?.[0] : ''}
        onChange={(e) =>
          onChange(e.target.value ? [Number(e.target.value)] : null)
        }
      />
    );
  }

  if (
    [
      EntityReportColumnType.Text,
      EntityReportColumnType.Picklist,
      EntityReportColumnType.Multipicklist,
    ].includes(columnType)
  ) {
    return (
      <AutocompleteTextfield
        label="Values"
        value={value || []}
        required
        multiple
        fullWidth
        freeSolo
        filterSelectedOptions
        clearOnBlur
        selectOnFocus
        options={options}
        filterOptions={(options, params) => {
          const filtered = createFilterOptions<string>()(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option);
          if (inputValue !== '' && !isExisting) {
            filtered.push(`Add "${inputValue}"`);
          }

          return filtered;
        }}
        onChange={(e: SyntheticEvent, newValues: string[]) => {
          const selectedValue = newValues
            .map((newValue) =>
              newValue.includes('Add "')
                ? /Add "(.*)"/gi.exec(newValue)?.[1]
                : newValue,
            )
            .filter(isTruthy);

          onChange(selectedValue.length ? selectedValue : null);
        }}
      />
    );
  }

  if (
    columnType === EntityReportColumnType.Date &&
    [
      EntityReportFilterOperator.Between,
      EntityReportFilterOperator.NotBetween,
    ].includes(operator)
  ) {
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" gap={2}>
          <DatePicker
            label="From"
            value={value?.[0] ? dayjs(value[0]) : null}
            sx={{ width: '100%' }}
            onChange={(newValue) => {
              onChange([
                newValue?.format('YYYY-MM-DD') || null,
                value?.[1] || null,
              ]);
            }}
          />
          <DatePicker
            label="To"
            value={value?.[1] ? dayjs(value[1]) : null}
            sx={{ width: '100%' }}
            onChange={(newValue) => {
              onChange([
                value?.[0] || null,
                newValue?.format('YYYY-MM-DD') || null,
              ]);
            }}
          />
        </Box>
      </Box>
    );
  }

  if (columnType === EntityReportColumnType.Date) {
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" gap={2}>
          <DatePicker
            label="Value"
            value={value?.[0] ? dayjs(value[0]) : null}
            sx={{ width: '100%' }}
            onChange={(newValue) => {
              onChange(newValue ? [newValue.format('YYYY-MM-DD')] : null);
            }}
          />

          <TextButton
            onClick={() => onChange([format(new Date(), 'yyyy-MM-dd')])}
            variant="primary"
            underlined
            sx={{ fontSize: '14px' }}
          >
            Today
          </TextButton>
        </Box>
      </Box>
    );
  }

  return null;
};
