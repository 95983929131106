import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Flex } from '@components';

import { authSelectors } from '@redux/auth';
import {
  deleteEntityReport,
  entityReportsSelectors,
} from '@redux/entityReports';

import { IEntityReport } from '@types';

export const DeleteReportModal: React.FC<{
  isOpen: boolean;
  entityReport: IEntityReport | null;
  onCloseModalClick: () => void;
}> = ({ isOpen, entityReport, onCloseModalClick }) => {
  const dispatch = useAppDispatch();

  const profile = useAppSelector(authSelectors.getProfileData);
  const isPerformingAction = useAppSelector(
    entityReportsSelectors.getEntityReportIsPerformingAction,
  );

  const onDelete = useCallback(() => {
    if (!entityReport?.id) return;

    dispatch(deleteEntityReport(entityReport.id));
    onCloseModalClick();
  }, [entityReport]);

  const isDeleteDisabled = profile?.email !== entityReport?.owner.email;

  if (!entityReport) return null;

  return (
    <Dialog open={isOpen} scroll="body" fullWidth>
      <DialogTitle>
        <Flex justifyContent="space-between">
          <Typography variant="h2">
            Delete "{entityReport.name}" from "{entityReport.folder}" folder
          </Typography>
          <CloseIcon
            onClick={onCloseModalClick}
            sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
          />
        </Flex>
      </DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this report?</Typography>
      </DialogContent>
      <DialogActions>
        <Flex justifyContent="end" gap={1}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onCloseModalClick}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onDelete}
            disabled={isDeleteDisabled || isPerformingAction}
          >
            Delete
          </Button>
        </Flex>
      </DialogActions>
    </Dialog>
  );
};
