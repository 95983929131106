export const CountryRegionOrder = ['LatAm', 'Europe', 'Asia', 'India', 'USA'];

export enum CityTier {
  TIER_1 = 'Tier 1',
  TIER_2 = 'Tier 2',
  TIER_3 = 'Tier 3',
}

export const JopOpeningCityMapTierToShortValue: Record<CityTier, string> = {
  [CityTier.TIER_1]: 'T1',
  [CityTier.TIER_2]: 'T2',
  [CityTier.TIER_3]: 'T3',
};
