import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormikErrors } from 'formik';

import {
  AppBar,
  Box,
  Button,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import { AppRoutes, EntityReportStep, EntityReportType } from '@constants';
import { EntityReportFormValues, IEntityReport } from '@types';
import { getEntityReportsSteps } from '@utils';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.brand.accent,
  color: theme.palette.text.invertedPrimary,
  padding: '0.625rem',
  borderRadius: '0.5rem',
  '&:hover': {
    backgroundColor: alpha(theme.palette.brand.accent, 0.7),
  },
}));

const NavbarButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
  },
}));

export const EntityReportFormHeader: React.FC<{
  entityReport: IEntityReport | null;
  type: EntityReportType | null;
  activeStep: EntityReportStep;
  errors: FormikErrors<EntityReportFormValues>;
  onSelectStep: (selectedStep: EntityReportStep) => void;
}> = ({ entityReport, type, activeStep, errors, onSelectStep }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackButtonClick = () => {
    if (location.state && location.state.from) {
      navigate(location.state.from);
    } else {
      navigate(`/${AppRoutes.ENTITY_REPORTS}`);
    }
  };

  const stepsOrder = getEntityReportsSteps(type);

  return (
    <React.Fragment>
      <AppBar
        position="static"
        sx={(theme) => ({
          boxShadow: 'none',
          backgroundColor: theme.palette.background.backgroundPrimary,
        })}
      >
        <Toolbar
          sx={{
            padding: {
              xs: '0.75rem 1rem',
            },
            justifyContent: 'space-between',
            gap: '0.35rem',
          }}
        >
          <Stack
            gap="0.5rem"
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <StyledIconButton
              size="large"
              aria-label="go back"
              onClick={handleBackButtonClick}
            >
              <ArrowBackRoundedIcon />
            </StyledIconButton>

            <Box>
              <Typography
                variant="h2"
                color="text.primary"
                noWrap
                sx={{
                  fontSize: {
                    xs: '1.5rem',
                    md: '1.125rem',
                  },
                  lineHeight: '120%',
                  mb: 0,
                }}
              >
                {entityReport ? 'Edit report' : 'New report'}
              </Typography>
              {!!entityReport && (
                <Typography variant="body2" color={'secondary'}>
                  {entityReport.name}
                </Typography>
              )}
            </Box>
          </Stack>
          <Box>
            <Stepper
              activeStep={stepsOrder.findIndex((e) => e === activeStep)}
              sx={{
                width: '450px',
                '& .MuiStepLabel-root .Mui-completed': {
                  color: '#3F8CFF !important',
                },
                '& .MuiStepLabel-root .Mui-active': {
                  color: '#4f4f4d !important',
                },
                '& .MuiStepLabel-root .Mui-error': {
                  color: '#c62828 !important',
                },
                '& .MuiStepConnector-root': {
                  display: 'none !important',
                },
              }}
            >
              {stepsOrder.map((label, idx) => {
                const hasTypeError =
                  label === EntityReportStep.Type &&
                  label !== activeStep &&
                  (!!errors.type || !!errors.source);
                const hasColumnsError =
                  label === EntityReportStep.Columns &&
                  label !== activeStep &&
                  !!errors.columns;
                const hasGroupingError =
                  label === EntityReportStep.Grouping &&
                  label !== activeStep &&
                  (!!errors.groupBy || !!errors.sortBy);
                const hasFiltersError =
                  label === EntityReportStep.Filters &&
                  label !== activeStep &&
                  !!errors.filters;
                const hasSaveError =
                  label === EntityReportStep.Save &&
                  label !== activeStep &&
                  (!!errors.name || !!errors.folder || !!errors.description);

                return (
                  <Step
                    key={label}
                    sx={{
                      padding: {
                        xs: '2px',
                        sm: '4px',
                        lg: '8px',
                      },
                    }}
                  >
                    <StepLabel
                      error={
                        hasTypeError ||
                        hasColumnsError ||
                        hasGroupingError ||
                        hasFiltersError ||
                        hasSaveError
                      }
                      onClick={() => onSelectStep(label)}
                      sx={{
                        cursor: 'pointer !important',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: { xs: 'none', sm: 'flex' },
                        color: '#4f4f4d',
                      }}
                      StepIconProps={{
                        classes: { text: 'MuiStepIcon-text' },
                      }}
                      StepIconComponent={({ active, completed, error }) => (
                        <Box
                          sx={{
                            width: 24,
                            height: 24,
                            borderRadius: '50%',
                            bgcolor: error
                              ? '#c62828'
                              : completed
                              ? '#3F8CFF'
                              : active
                              ? '#4f4f4d'
                              : 'lightgrey',
                            display: { xs: 'none', md: 'none', lg: 'flex' },
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            fontWeight: 300,
                          }}
                        >
                          {idx + 1}
                        </Box>
                      )}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>

          <NavbarButtonsContainer>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleBackButtonClick}
            >
              Cancel
            </Button>
          </NavbarButtonsContainer>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};
