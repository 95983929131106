import { Box, Paper, Typography } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { CustomButton } from '@components';
import puzzleSrc from '@assets/puzzle.png';

import { useAppSelector } from '@redux/hooks';
import { authSelectors } from '@redux/auth';

import { checkUserCanCreateEditEntityReport } from '@utils';

export const EmptyEntityReportsState: React.FC<{
  onCreateEntityReportClick: () => void;
}> = ({ onCreateEntityReportClick }) => {
  const profile = useAppSelector(authSelectors.getProfileData);

  const isEntityReportCreationDisabled =
    !checkUserCanCreateEditEntityReport(profile);

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
      }}
    >
      <Box
        component={Paper}
        sx={{
          margin: '0 1.5rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <img src={puzzleSrc} alt="puzzle" />
        <Typography variant="h2" m="0.5rem">
          You don’t have any reports
        </Typography>
        <>
          <Typography variant="body1" color="text.secondary" mb="2rem">
            You can create a new report using existing form
          </Typography>

          <CustomButton
            size="small"
            color="secondary"
            className="ae-custom-button"
            label="Create new report"
            startIcon={<AddRoundedIcon />}
            onClick={onCreateEntityReportClick}
            isDisabled={isEntityReportCreationDisabled}
          />
        </>
      </Box>
    </Box>
  );
};
