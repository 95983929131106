import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import { EntityReportFormik } from '@types';
import {
  EntityReportSourceOptions,
  EntityReportType,
  EntityReportTypeOptions,
} from '@constants';

export const EntityReportFormTypeStep: React.FC<{
  formik: EntityReportFormik;
}> = ({ formik }) => {
  const sourceError =
    (formik.touched.source || !!formik.submitCount) && formik.errors.source
      ? formik.errors.source
      : null;
  const typeError =
    (formik.touched.type || !!formik.submitCount) && formik.errors.type
      ? formik.errors.type
      : null;

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      pt={5}
      pb={2}
      width={450}
    >
      <Typography variant="h3">
        Select data module and type of the report
      </Typography>
      <Box mx={-1}>
        <RadioGroup
          value={formik.values.source}
          onChange={(e) => formik.setFieldValue('source', e.target.value)}
          row
          sx={(theme) => ({
            background: theme.palette.highlight.neutral,
            padding: '16px 8px',
            borderRadius: '8px',
          })}
        >
          {EntityReportSourceOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              label={option.label}
              value={option.value}
              control={<Radio disableRipple />}
            />
          ))}
        </RadioGroup>
        {!!sourceError && (
          <Typography
            variant="body2"
            color="text.danger"
            sx={{ marginLeft: '14px', marginTop: '3px' }}
          >
            {sourceError}
          </Typography>
        )}
      </Box>
      <Box mx={-1}>
        <RadioGroup
          value={formik.values.type}
          onChange={(e) =>
            formik.setValues({
              ...formik.values,
              type: e.target.value as EntityReportType,
              groupBy: null,
              sortBy: null,
            })
          }
          sx={{ gap: 2, paddingLeft: '8px' }}
        >
          {EntityReportTypeOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              label={option.label}
              value={option.value}
              control={<Radio disableRipple />}
            />
          ))}
        </RadioGroup>
        {!!typeError && (
          <Typography
            variant="body2"
            color="text.danger"
            sx={{ marginLeft: '14px', marginTop: '3px' }}
          >
            {typeError}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
