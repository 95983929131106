import {
  PositionType,
  JobOpeningSourceOptions,
  JobOpeningsPriority,
  URL_REGEX,
} from '@constants';
import { validateEmail } from '@utils';
import * as yup from 'yup';

export const validationSchema = yup.object({
  Job_Opening_Name: yup.string().required('Position title is required'),
  Priority: yup.string().required('Priority is required'),
  Position_type: yup.string().required('Source is required'),
  Number_of_Positions: yup.number().required('Quantity is required'),

  Location_Tier_Outreach: yup
    .array(yup.string().required())
    .min(1, 'Location is required')
    .required('Location is required'),

  Specialization: yup.string().required('Specialization is required'),
  Technical_Flow: yup.string().required('Technical flow is required'),
  Seniority_level: yup
    .array(yup.string().required())
    .min(1, 'Seniority is required')
    .required('Seniority is required'),

  Must_Haves1: yup.string().required('Must haves field is required'),
  Multi_Line_4: yup.string().required('Main tasks field is required'),
  Google_Drive_Folder: yup
    .string()
    .optional()
    .max(255, 'Max length is 255')
    .matches(URL_REGEX, 'Enter correct url!')
    .when('Priority', (source, schema) => {
      const priority = source[0];

      if (priority !== JobOpeningsPriority.PreOpen)
        return schema.required('Job description URL is required');
      return schema;
    })
    .nullable(),
  TI_Requirements_Link: yup
    .string()
    .max(255, 'Max length is 255')
    .matches(URL_REGEX, 'Enter correct url!')
    .optional()
    .nullable(),

  Test_Task: yup.string().required('Test task field is required'),
  Hiring_Manager_email: yup
    .string()
    .required('Hiring manager email is required'),

  Client_Name_New: yup.string().required('Client name is required'),
  PDM: yup
    .string()
    .optional()
    .when('Position_type', (source, schema) => {
      const sourceData = JobOpeningSourceOptions.find(
        (e) => e.value === source?.[0],
      );
      if (sourceData?.positionType === PositionType.BILLABLE)
        return schema.required('Portfolio Delivery Manager is required');
      return schema;
    })
    .nullable(),
  Delivery_Manager: yup
    .string()
    .optional()
    .when('Position_type', (source, schema) => {
      const sourceData = JobOpeningSourceOptions.find(
        (e) => e.value === source?.[0],
      );
      if (sourceData?.positionType === PositionType.BILLABLE)
        return schema.required('Delivery manager is required');
      return schema;
    })
    .nullable(),
  Client_submission_emails: yup
    .string()
    .optional()
    .test(
      'emails',
      'Invalid email address',
      (value) =>
        !value ||
        (!!value &&
          value
            .split(',')
            .map((e) => e.trim())
            .every(validateEmail)),
    )
    .nullable(),

  Other_Comments: yup
    .string()
    .max(250, 'Max length is 250')
    .optional()
    .nullable(),
  Technical_Interviewers_Not_in_list: yup
    .string()
    .optional()
    .test(
      'emails',
      'Invalid email address',
      (value) =>
        !value ||
        (!!value &&
          value
            .split(',')
            .map((e) => e.trim())
            .every(validateEmail)),
    )
    .nullable(),
  Interview_process: yup
    .string()
    .max(250, 'Max length is 250')
    .optional()
    .nullable(),
  Candidates_spreadsheet: yup
    .string()
    .matches(URL_REGEX, 'Enter correct url!')
    .optional()
    .nullable(),
  Jira_url: yup
    .string()
    .matches(URL_REGEX, 'Enter correct url!')
    .optional()
    .nullable(),
});
