import { isEntityReportStepEqualOrHigherThan } from '@utils';
import {
  EntityReportFilterOperator,
  EntityReportFilterOperatorWithoutValue,
  EntityReportStep,
  EntityReportType,
} from '@constants';
import * as yup from 'yup';

const MaxLengthErrorMessage = (maxLength: number) =>
  `Max length is ${maxLength} symbols`;

export const entityReportFormValidationSchema = yup.object({
  name: yup
    .string()
    .max(255, MaxLengthErrorMessage(255))
    .optional()
    .when(['step'], (formData, schema) => {
      const step: EntityReportStep = formData[0];

      if (isEntityReportStepEqualOrHigherThan(step, EntityReportStep.Save))
        return schema.required('Please select report title');

      return schema;
    })
    .nullable(),
  folder: yup
    .string()
    .max(255, MaxLengthErrorMessage(255))
    .optional()
    .when(['step'], (formData, schema) => {
      const step: EntityReportStep = formData[0];

      if (isEntityReportStepEqualOrHigherThan(step, EntityReportStep.Save))
        return schema.required('Please enter report folder');

      return schema;
    })
    .nullable(),
  description: yup
    .string()
    .max(5000, MaxLengthErrorMessage(5000))
    .optional()
    .nullable(),
  type: yup
    .string()
    .optional()
    .when(['step'], (formData, schema) => {
      const step: EntityReportStep = formData[0];

      if (isEntityReportStepEqualOrHigherThan(step, EntityReportStep.Type))
        return schema.required('Please select report type');

      return schema;
    })
    .nullable(),
  source: yup
    .string()
    .optional()
    .when(['step'], (formData, schema) => {
      const step: EntityReportStep = formData[0];

      if (isEntityReportStepEqualOrHigherThan(step, EntityReportStep.Type))
        return schema.required('Please select report scope');

      return schema;
    })
    .nullable(),
  groupBy: yup
    .string()
    .optional()
    .when(['step', 'type'], (formData, schema) => {
      const step: EntityReportStep = formData[0];
      const type: EntityReportType = formData[1];

      if (
        isEntityReportStepEqualOrHigherThan(step, EntityReportStep.Grouping) &&
        type === EntityReportType.Grouped
      )
        return schema.required('Please select report grouping');

      return schema;
    })
    .nullable(),
  columns: yup
    .array()
    .optional()
    .when(['step'], (formData, schema) => {
      const step: EntityReportStep = formData[0];

      if (isEntityReportStepEqualOrHigherThan(step, EntityReportStep.Columns))
        return schema
          .min(1, 'Please select at least 1 column')
          .required('Please select at least 1 column');

      return schema;
    })
    .nullable(),
  filters: yup
    .array(
      yup.object({
        field: yup.string().nullable().required('Column is required.'),
        operator: yup.string().nullable().required('Operator is required.'),
        value: yup
          .array()
          .optional()
          .when(['operator'], (formData, schema) => {
            const operator: EntityReportFilterOperator = formData[0];

            if (
              [
                EntityReportFilterOperator.Between,
                EntityReportFilterOperator.NotBetween,
              ].includes(operator)
            ) {
              return schema
                .min(2, 'Please specify date range')
                .required('Please specify date range');
            }

            if (
              operator &&
              !EntityReportFilterOperatorWithoutValue.includes(operator)
            )
              return schema
                .min(1, 'Please specify at least 1 value')
                .required('Please specify at least 1 value');

            return schema;
          })
          .nullable(),
      }),
    )
    .optional()
    .when(['step'], (formData, schema) => {
      const step: EntityReportStep = formData[0];

      if (isEntityReportStepEqualOrHigherThan(step, EntityReportStep.Filters))
        return schema
          .min(1, 'Please specify at least 1 filter')
          .required('Please specify at least 1 filter');

      return schema;
    })
    .nullable(),
});
