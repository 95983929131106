import { ReactNode } from 'react';

import { Paper, Table, TableContainer } from '@mui/material';
import {
  ForbiddenView,
  LoaderSkeleton,
  ZohoPositionDisclaimer,
} from '@components';
import { EntityMainTableHeader } from './components/EntityMainTableHeader';
import { EntityMainTableBody } from './components/EntityMainTableBody';
import { EntityMainTablePagination } from './components/EntityMainTablePagination';

import {
  isStatusComplete,
  isStatusFailed,
  isStatusForbidden,
  isStatusLoading,
} from '@utils';
import { IEntityMainTableHeaderCell } from '@types';
import { ApiStatus, OrderDirection } from '@constants';
import { ErrorView } from '../ErrorView/ErrorView';

interface IProps<T extends { id: string }> {
  tableHeaders: IEntityMainTableHeaderCell[];
  apiStatus: ApiStatus;
  errorMessage?: string | null;
  data: T[] | null;
  groupBy?: string | null;
  groupIcon?: ReactNode;
  showGroupedAmount?: boolean;
  sortOptions?: {
    direction: OrderDirection | null;
    sortBy: string | null;
    onSort: (value: { field: string; direction: OrderDirection }) => void;
  };
  paginationOptions?: {
    totalCount: number;
    onFetchNextPage: () => void;
  };
  EmptyStateComponent: ReactNode;
  renderRowComponent: (value: T) => ReactNode;
  onRowClick: (value: string) => void;
  isPositionPage?: boolean;
}

export const EntityMainTable = <T extends { id: string }>({
  tableHeaders,
  apiStatus,
  errorMessage,
  isPositionPage,
  data,
  groupBy,
  groupIcon,
  showGroupedAmount,
  sortOptions,
  paginationOptions,
  EmptyStateComponent,
  renderRowComponent,
  onRowClick,
}: IProps<T>) => {
  const isLoading = isStatusLoading(apiStatus);

  if (!data?.length && isStatusComplete(apiStatus)) {
    return <>{EmptyStateComponent}</>;
  }

  if (isStatusFailed(apiStatus)) {
    return <ErrorView description={errorMessage} />;
  }

  if (isStatusForbidden(apiStatus)) {
    return <ForbiddenView />;
  }

  if (!data?.length && !isStatusComplete(apiStatus)) {
    return <LoaderSkeleton />;
  }

  if (!data) return null;

  return (
    <>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ flex: 1, mx: 1, mb: 1, width: 'auto' }}
      >
        <Table stickyHeader sx={{ tableLayout: 'auto' }}>
          <EntityMainTableHeader
            tableHeaders={tableHeaders}
            sortOptions={sortOptions}
            groupBy={groupBy}
          />
          <EntityMainTableBody
            data={data}
            groupBy={groupBy}
            groupIcon={groupIcon}
            showGroupedAmount={showGroupedAmount}
            tableHeadersCount={tableHeaders.length}
            onRowClick={onRowClick}
            renderRowComponent={renderRowComponent}
          />
        </Table>
        {!!paginationOptions && (
          <EntityMainTablePagination
            totalCount={paginationOptions.totalCount}
            currentCount={data.length}
            isLoading={isLoading}
            onFetchNextPage={paginationOptions.onFetchNextPage}
          />
        )}
      </TableContainer>
      {isPositionPage && (
        <ZohoPositionDisclaimer
          continerStyle={{
            backgroundColor: 'background.backgroundPrimary',
          }}
        />
      )}
    </>
  );
};
