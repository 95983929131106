import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import { EntityListLayout } from '@components';
import { EmptyEntityReportsState } from './components/EmptyState';
import { EntityReportRow } from './components/EntityReportRow';

import { authSelectors } from '@redux/auth';
import {
  entityReportsSelectors,
  fetchEntityReports,
} from '@redux/entityReports';

import {
  AppRoutes,
  EntityReportsGroupOptions,
  EntityReportsGroupingColumn,
  EntityReportsSortingColumn,
  tableHeadersEntityReports,
} from '@constants';
import { checkUserCanCreateEditEntityReport, isStatusIdle } from '@utils';
import {
  IEntityReportListItem,
  IEntityReportsOptions,
  OrderDirection,
} from '@types';
import { DeleteReportModal } from './components/DeleteReportModal';
import { orderBy } from 'lodash';

export const EntityReportsDashboardPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { apiStatus, data } = useAppSelector(
    entityReportsSelectors.getEntityReportsAPIData,
  );
  const profile = useAppSelector(authSelectors.getProfileData);

  const [deleteReportId, setDeleteReportId] = useState<string | null>(null);
  useEffect(() => {
    if (isStatusIdle(apiStatus)) dispatch(fetchEntityReports());
  }, [apiStatus]);

  const entityReportsOptions = useMemo(
    () => ({
      groupBy:
        (searchParams.get('groupBy') as EntityReportsGroupingColumn) || null,
      sortBy:
        (searchParams.get('sortBy') as EntityReportsSortingColumn) || null,
      order: (searchParams.get('order') as OrderDirection) || null,
    }),
    [searchParams],
  );
  const defaultEntityReportsOptions: Record<string, any> = useMemo(
    () => ({
      groupBy: EntityReportsGroupingColumn.Folder,
      sortBy: EntityReportsSortingColumn.Folder,
      order: OrderDirection.DESC,
    }),
    [],
  );

  const entityReportsListData = useMemo(() => {
    const list =
      data?.map((report) => ({
        ...report,
        columnsAmount: report.columns.length,
      })) || [];

    return entityReportsOptions.sortBy
      ? orderBy(
          list,
          entityReportsOptions.sortBy,
          entityReportsOptions.order || OrderDirection.ASC,
        )
      : list;
  }, [data, entityReportsOptions]);

  const onDeleteReportClick = useCallback(
    (id: string) => setDeleteReportId(id),
    [],
  );
  const onCloseDeleteReportModal = useCallback(
    () => setDeleteReportId(null),
    [],
  );

  const onEditReportClick = useCallback(
    (id: string) => {
      navigate(`/${AppRoutes.ENTITY_REPORTS}/${id}/edit`, {
        state: { from: `${location.pathname}${location.search}` },
      });
    },
    [navigate, location],
  );

  const renderRowComponent = useCallback(
    (rowData: IEntityReportListItem) => (
      <EntityReportRow
        data={rowData}
        groupBy={entityReportsOptions.groupBy}
        onDeleteClick={onDeleteReportClick}
        onEditClick={onEditReportClick}
      />
    ),
    [entityReportsOptions.groupBy],
  );

  const onRowClick = useCallback(
    (id: string) => {
      navigate(`/${AppRoutes.ENTITY_REPORTS}/${id}`, {
        state: { from: `${location.pathname}${location.search}` },
      });
    },
    [navigate, location],
  );

  const onCreateEntityReportClick = useCallback(
    () =>
      navigate(`/${AppRoutes.CREATE_ENTITY_REPORT}`, {
        state: { from: `${location.pathname}${location.search}` },
      }),
    [],
  );

  return (
    <EntityListLayout<IEntityReportsOptions, IEntityReportListItem>
      title="Reports"
      apiStatus={apiStatus}
      tableProps={{
        headers: tableHeadersEntityReports,
        data: entityReportsListData,
        renderEmptyState: () => (
          <EmptyEntityReportsState
            onCreateEntityReportClick={onCreateEntityReportClick}
          />
        ),
        renderRowComponent,
        onRowClick,
      }}
      dataOptions={{
        value: entityReportsOptions,
        default: defaultEntityReportsOptions,
      }}
      groupProps={{
        groupDataOptionKey: 'groupBy',
        groupIcon: <FolderOpenOutlinedIcon sx={{ color: '#0000008F' }} />,
        options: EntityReportsGroupOptions,
      }}
      createEntityProps={{
        label: 'New report',
        onClick: onCreateEntityReportClick,
        disabled: !checkUserCanCreateEditEntityReport(profile),
      }}
    >
      <DeleteReportModal
        isOpen={!!deleteReportId}
        entityReport={
          data?.find((report) => report.id === deleteReportId) || null
        }
        onCloseModalClick={onCloseDeleteReportModal}
      />
    </EntityListLayout>
  );
};
