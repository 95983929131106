import { Box, List, ListItem, Typography } from '@mui/material';
import { IQuestionVI } from '@types';

export const VIQuestionsList: React.FC<{
  questionsList: IQuestionVI[];
}> = ({ questionsList }) => {
  return (
    <List
      sx={{
        padding: 0,
        listStyle: 'none',
        width: '100%',
        marginTop: '18px',
      }}
    >
      {questionsList?.map((question: IQuestionVI) => (
        <ListItem
          key={question.questionNumber}
          sx={(theme) => ({
            padding: '0.5rem 1rem 1rem 1rem !important',
            marginBottom: '0.25rem !important',
            borderRadius: '0.25rem',
            background: theme.palette.highlight.sectionLight,
          })}
        >
          <Box display="flex" gap={1}>
            <Typography variant="body2" color="text.secondary">
              Question {question.questionNumber} •
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Preparation: {question.preparation * 60} sec •
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Answer: {question.duration} min
            </Typography>
          </Box>
          <Typography variant="body1" mt={1}>
            {question.title}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};
