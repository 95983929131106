import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import { TransferList } from '@components';
import { Box, Typography } from '@mui/material';

import { fetchEntityReportColumns, optionsSelectors } from '@redux/options';

import { EntityReportFormik } from '@types';
import { isStatusIdle, transformToTransferListOption } from '@utils';

export const EntityReportFormColumnStep: React.FC<{
  formik: EntityReportFormik;
}> = ({ formik }) => {
  const dispatch = useAppDispatch();

  const { apiStatus, data } = useAppSelector(
    optionsSelectors.getEntityReportColumnsAPIData,
  );

  useEffect(() => {
    if (isStatusIdle(apiStatus)) {
      dispatch(fetchEntityReportColumns());
    }
  }, []);

  const columnsError =
    (formik.touched.columns || !!formik.submitCount) && formik.errors.columns
      ? formik.errors.columns
      : null;

  const allOptions = useMemo(
    () =>
      transformToTransferListOption(
        data.filter((e) => e.source === formik.values.source!),
      ),
    [data],
  );
  const selectedOptions = useMemo(
    () =>
      transformToTransferListOption(
        data.filter(
          (e) =>
            e.source === formik.values.source! &&
            formik.values.columns?.includes(e.dwhField),
        ),
      ),
    [data, formik.values.columns],
  );

  return (
    <Box py={2} height="100%" width={550}>
      {!!columnsError && (
        <Typography variant="subtitle2" color="text.danger">
          {columnsError}
        </Typography>
      )}
      <TransferList
        options={allOptions}
        selectedOptions={selectedOptions}
        onChange={(e) =>
          formik.setFieldValue(
            'columns',
            e.map((e) => e.value),
          )
        }
      />
    </Box>
  );
};
