import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  EntityListLayout,
  CandidatesListFilters,
  CreateCandidateModal,
  EmptyStateCandidates,
  TableCellNewHomers,
  TableCellCandidates,
} from '@components';

import {
  DEFAULT_LIMIT,
  candidatesSelectors,
  setCandidatesOptionField,
} from '@redux/candidates';
import { authSelectors } from '@redux/auth';

import { checkUserCanCreateCandidate } from '@utils';
import {
  AppRoutes,
  CandidateDetailsTabs,
  CandidateSortingOptions,
  CandidateStage,
  CandidateTypeOfOutboundChannel,
  CandidateFilterFields,
  tableHeadersCandidates,
  tableHeadersNewHomers,
  CandidateStageLabel,
  CandidateTypeLabel,
} from '@constants';
import { ICandidatesOptions, IDWHCandidate, OrderDirection } from '@types';
import { isNull, omitBy } from 'lodash';
import { useTitle } from '@hooks/useTitle';
import { PageTitle } from 'constants/pageTitle';

interface IProps {
  stage?: CandidateStage;
  typeOfOutboundChannel?: CandidateTypeOfOutboundChannel;
}

export const CandidatesDashboardPage: React.FC<IProps> = ({
  stage,
  typeOfOutboundChannel,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const profile = useAppSelector(authSelectors.getProfileData);
  const candidatesLength = useAppSelector(
    candidatesSelectors.getCandidatesTotal,
  );
  const candidatesData = useAppSelector(candidatesSelectors.getCandidatesData);
  const candidatesOptions = useAppSelector(
    candidatesSelectors.getCandidatesOptions,
  );
  const apiStatus = useAppSelector(candidatesSelectors.getCandidatesStatus);

  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(
      (stage && `${CandidateStageLabel[stage]} candidates`) ||
        (typeOfOutboundChannel &&
          `${CandidateTypeLabel[typeOfOutboundChannel]} candidates`) ||
        PageTitle.CANDIDATES,
    );
  }, [setPageTitle, stage, typeOfOutboundChannel]);

  const isNewHomeStage = stage === CandidateStage.NEW_HOME;
  const defaultCanidateOptions: Record<string, any> = useMemo(
    () =>
      omitBy(
        {
          sortBy: CandidateSortingOptions.NAME,
          order: OrderDirection.ASC,
          offset: 0,
          limit: DEFAULT_LIMIT,
          stage: stage || null,
          typeOfOutboundChannel: typeOfOutboundChannel || null,
        },
        isNull,
      ),
    [],
  );

  const blockedCandidateOptions = useMemo(() => {
    const res: Array<keyof ICandidatesOptions> = [];
    if (stage) res.push('stage');
    if (typeOfOutboundChannel) res.push('typeOfOutboundChannel');

    return res;
  }, [stage, typeOfOutboundChannel]);

  const onChangeCandidateOptions = useCallback(
    (value: Partial<Record<keyof ICandidatesOptions, any[]>>) =>
      dispatch(setCandidatesOptionField(value)),
    [],
  );

  // Table
  const tableHeaders = useMemo(
    () => (isNewHomeStage ? tableHeadersNewHomers : tableHeadersCandidates),
    [isNewHomeStage],
  );

  const onRowClick = useCallback((id: string) => {
    navigate(`/${AppRoutes.CANDIDATES}/${id}/${CandidateDetailsTabs.PROFILE}`, {
      state: { from: `${location.pathname}${location.search}` },
    });
  }, []);

  const renderRowComponent = useCallback(
    (rowData: IDWHCandidate) =>
      isNewHomeStage ? (
        <TableCellNewHomers candidate={rowData} />
      ) : (
        <TableCellCandidates candidate={rowData} />
      ),
    [isNewHomeStage],
  );

  // Create candidate
  const [isCreateCandidateModalOpen, setIsCreateCandidateModalOpen] =
    useState(false);

  const onOpenCreateCandidateModal = useCallback(
    () => setIsCreateCandidateModalOpen(true),
    [],
  );
  const onCloseCreateCandidateModal = useCallback(
    () => setIsCreateCandidateModalOpen(false),
    [],
  );

  return (
    <EntityListLayout<ICandidatesOptions, IDWHCandidate>
      title={`${candidatesLength || 0} candidates`}
      apiStatus={apiStatus}
      tableProps={{
        headers: tableHeaders,
        data: candidatesData,
        renderEmptyState: ({ hasFilters, isLoading, resetFilters }) => (
          <EmptyStateCandidates
            hasFilters={hasFilters!}
            isLoading={isLoading!}
            resetFilters={resetFilters!}
          />
        ),
        renderRowComponent,
        onRowClick,
        enablePagination: true,
        totalCount: candidatesLength,
      }}
      dataOptions={{
        value: candidatesOptions,
        default: defaultCanidateOptions,
        blocked: blockedCandidateOptions,
        filter: CandidateFilterFields,
        onChange: onChangeCandidateOptions,
      }}
      searchProps={{
        searchDataOptionKey: 'search',
        placeholder: 'Name, email, ID, etc...',
      }}
      filterProps={{
        renderFilters: ({ optionList, isLoading, onChange }) => (
          <CandidatesListFilters
            isLoading={isLoading}
            value={optionList}
            handleChange={onChange}
            stage={stage}
          />
        ),
      }}
      createEntityProps={
        typeOfOutboundChannel === CandidateTypeOfOutboundChannel.Selfgen
          ? {
              label: 'Add candidate',
              onClick: onOpenCreateCandidateModal,
              disabled: !checkUserCanCreateCandidate(profile),
            }
          : undefined
      }
    >
      <CreateCandidateModal
        isOpen={isCreateCandidateModalOpen}
        onCloseModalClick={onCloseCreateCandidateModal}
      />
    </EntityListLayout>
  );
};
