import React from 'react';
import { useAppSelector } from '@redux/hooks';

import {
  Box,
  Chip,
  IconButton,
  styled,
  TableCell,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import GroupIcon from '@mui/icons-material/Group';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { Flex } from '@components';

import { authSelectors } from '@redux/auth';

import {
  IEntityReportListItem,
  IJobOpeningDetails,
  IZohoCandidate,
} from '@types';
import {
  CandidateFieldsLabels,
  EntityReportsGroupingColumn,
  EntityReportSourceLabel,
  EntityReportTypeLabel,
  JobOpeningFieldsLabels,
} from '@constants';
import { formatDateWithTime } from '@utils';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFF',
    padding: '16px 16px 32px 24px',
    border: 'none',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px -1px #00000033',
  },
}));

export const EntityReportRow: React.FC<{
  data: IEntityReportListItem;
  groupBy: EntityReportsGroupingColumn | null;
  onEditClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
}> = ({ data, groupBy, onEditClick, onDeleteClick }) => {
  const profile = useAppSelector(authSelectors.getProfileData);

  const {
    id,
    name,
    description,
    folder,
    source,
    type,
    columnsAmount,
    columns,
    owner,
    shareWith,
    updatedAt,
    createdAt,
  } = data;

  return (
    <>
      <TableCell style={{ verticalAlign: 'center' }}>
        <CustomTooltip
          title={
            description ? (
              <Flex flexDirection="column" gap={1} alignItems="start">
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontSize="11px"
                >
                  Description
                </Typography>
                <Typography variant="body1" sx={{ color: '#000000DE' }}>
                  {description}
                </Typography>
              </Flex>
            ) : (
              description
            )
          }
        >
          <Typography variant="body1" color="text.primary">
            {name}
          </Typography>
        </CustomTooltip>
      </TableCell>
      {groupBy !== EntityReportsGroupingColumn.Folder && (
        <TableCell style={{ verticalAlign: 'top' }}>{folder}</TableCell>
      )}
      <TableCell style={{ verticalAlign: 'top' }}>
        {EntityReportSourceLabel[source]}
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        {EntityReportTypeLabel[type]}
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        <Flex gap={1}>
          <ViewWeekIcon
            sx={{ color: 'rgba(0, 0, 0, 0.38)' }}
            fontSize="small"
          />
          <CustomTooltip
            title={
              <>
                <Typography variant="body2" color="text.secondary">
                  Columns
                </Typography>
                <Box component="ul" sx={{ paddingLeft: '24px' }}>
                  {columns.map((column) => (
                    <Typography
                      component="li"
                      key={column}
                      variant="body1"
                      sx={{ color: '#000000DE' }}
                    >
                      {CandidateFieldsLabels[column as keyof IZohoCandidate] ||
                        JobOpeningFieldsLabels[
                          column as keyof IJobOpeningDetails
                        ] ||
                        column}
                    </Typography>
                  ))}
                </Box>
              </>
            }
          >
            <Typography variant="body1" color="text.primary">
              {columnsAmount} columns
            </Typography>
          </CustomTooltip>
        </Flex>
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        <Flex gap={1}>
          {profile?.email !== owner.email && (
            <GroupIcon sx={{ color: 'rgba(0, 0, 0, 0.38)' }} fontSize="small" />
          )}
          <CustomTooltip
            title={
              <Flex flexDirection="column" gap={2}>
                <Flex flexDirection="column" gap={1} alignItems="start">
                  <Typography variant="body2" color="text.secondary">
                    Owner
                  </Typography>
                  <Chip
                    label={owner.name}
                    icon={<AccountCircleRoundedIcon />}
                  />
                </Flex>
                {!!shareWith?.length && (
                  <Flex flexDirection="column" gap={1} alignItems="start">
                    <Typography variant="body2" color="text.secondary">
                      Shared with
                    </Typography>
                    {shareWith.map((person) => (
                      <Chip
                        key={person.name}
                        label={person.name}
                        icon={<AccountCircleRoundedIcon />}
                      />
                    ))}
                  </Flex>
                )}
              </Flex>
            }
          >
            <Typography variant="body1" color="text.primary">
              {owner.name}
            </Typography>
          </CustomTooltip>
        </Flex>
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        {formatDateWithTime(updatedAt)}
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }}>
        {formatDateWithTime(createdAt)}
      </TableCell>
      <TableCell style={{ verticalAlign: 'top' }} align="left">
        <Flex>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onEditClick(id);
            }}
          >
            <EditIcon
              sx={(theme) => ({ color: theme.palette.text.link })}
              fontSize="small"
            />
          </IconButton>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onDeleteClick(id);
            }}
          >
            <DeleteIcon
              sx={(theme) => ({ color: theme.palette.text.link })}
              fontSize="small"
            />
          </IconButton>
        </Flex>
      </TableCell>
    </>
  );
};
