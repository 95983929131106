import React from 'react';
import { useNavigate } from 'react-router';
import { Typography, Button, Paper, Stack } from '@mui/material';
import { AppRoutes, JobOpeningFormMode } from '@constants';
import { JobOpeningFormik } from '@types';
import { ZohoPositionDisclaimer } from '@components/common';

export const Footer: React.FC<{
  formik: JobOpeningFormik;
  isFormSubmitting: boolean;
  mode: JobOpeningFormMode;
}> = ({ formik, isFormSubmitting, mode }) => {
  const navigate = useNavigate();

  return (
    <Paper
      elevation={1}
      sx={{
        mb: '1px',
        pt: 4,
        pb: 6,
        paddingX: 5,
        display: 'flex',
        justifyContent: 'space-between',
        justifyItems: 'center',
        flexDirection: { xs: 'column', md: 'row' },
        gap: { xs: '20px', md: '0px' },
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Button
          variant="contained"
          onClick={() => formik.handleSubmit()}
          sx={{
            height: '40px',
            backgroundColor: 'text.link',
            textTransform: 'none',
            display: 'flex',
            width: '80px',
          }}
          disabled={!formik.dirty || isFormSubmitting}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            navigate(`/${AppRoutes.JOB_OPENINGS}`);
          }}
          sx={{
            height: '40px',
            backgroundColor: '#3F8CFF14',
            color: '#42A5F5',
            textTransform: 'none',
            display: 'flex',
            width: '80px',
          }}
        >
          Back
        </Button>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          ✻ fill-in required fields
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <ZohoPositionDisclaimer mode={mode} />
      </Stack>
    </Paper>
  );
};
