import { JobOpeningFields } from '@types';

export const TextfieldJobOpeningFields: Array<keyof JobOpeningFields> = [
  'Job_Opening_Name',
  'Google_Drive_Folder',
  'TI_Requirements_Link',
  'Candidates_spreadsheet',
  'Jira_url',
];

export const TextareaJobOpeningFields: Array<keyof JobOpeningFields> = [
  'Must_Haves1',
  'Multi_Line_4',
  'Multi_Line_5',
  'Client_submission_emails',
  'Other_Comments',
  'Technical_Interviewers_Not_in_list',
  'Interview_process',
];

export const NumberfieldJobOpeningFields: Array<keyof JobOpeningFields> = [
  'Number_of_submissions',
  'maxCalls',
];

export const AutocompleteSingleselectJobOpeningFields: Array<
  keyof JobOpeningFields
> = [
  'Priority',
  'Timezone_Requirements',
  'Test_Task',
  'Candidate_Recruiter',
  'Project_interview_type',
  'Location_of_Closing',
  'Seniority_of_closing',
];

export const AutocompltereMultiselectJobOpeningFields: Array<
  keyof JobOpeningFields
> = ['Responsible_Recruiter'];

export const DateJobOpeningFields: Array<keyof JobOpeningFields> = [
  'Date_Closed',
  'Reason_of_Closing',
  'First_submission',
  'Candidate_Start_Date',
];

export const JobOpeningFieldsHelpText: Record<string, string> = {
  Google_Drive_Folder:
    'Always share access (without notifying) with marketing@agileengine.com, inbound@agileengine.com and recruiting@agileengine.com',
};

export enum TTSubmissionValue {
  Yes = 'Yes',
  No = 'No',
}

export enum InterviewStepValueValue {
  IncludeTTTinSubmission = 'Include Test Task in submission',
  TI = 'Technical interview',
  TLIntro = 'Tech Lead intro',
  Submission = 'Submissions',
  CI = 'Client interview',
  Reports = 'Biweekly reports',
}
