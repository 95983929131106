import { SyntheticEvent } from 'react';

import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderOptionState,
  FilterOptionsState,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';

export const AutocompleteTextfield: React.FC<{
  id?: string;
  name?: string;
  options?: any[];
  value: any;
  onChange?: (e: SyntheticEvent, newValue: any) => void;
  onBlur?: () => void;
  getOptionLabel?: (option: any) => string;
  groupBy?: (option: any) => string;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  placeholder?: string;
  multiple?: boolean;
  filterSelectedOptions?: boolean;
  freeSolo?: boolean;
  selectOnFocus?: boolean;
  clearOnBlur?: boolean;
  required?: boolean;
  disableClearable?: boolean;
  sx?: SxProps<Theme>;
  filterOptions?: (options: any[], state: FilterOptionsState<any>) => any[];
  getOptionDisabled?: (options: any) => boolean;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
  renderTags?: (
    value: any[],
    getTagProps: AutocompleteRenderGetTagProps,
  ) => React.ReactNode;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
}> = ({
  id,
  name,
  options = [],
  value,
  onChange,
  onBlur,
  getOptionLabel,
  groupBy,
  label,
  error,
  disabled,
  fullWidth,
  helperText,
  placeholder,
  multiple,
  filterSelectedOptions,
  freeSolo,
  selectOnFocus,
  clearOnBlur,
  required,
  filterOptions,
  getOptionDisabled,
  renderOption,
  renderTags,
  isOptionEqualToValue,
  disableClearable,
  sx,
}) => (
  <Autocomplete
    id={id}
    options={options}
    value={value}
    onChange={onChange}
    disabled={disabled}
    fullWidth={fullWidth}
    getOptionLabel={getOptionLabel}
    groupBy={groupBy}
    onBlur={onBlur}
    multiple={multiple}
    filterSelectedOptions={filterSelectedOptions}
    freeSolo={freeSolo}
    selectOnFocus={selectOnFocus}
    clearOnBlur={clearOnBlur}
    filterOptions={filterOptions}
    getOptionDisabled={getOptionDisabled}
    renderOption={renderOption}
    renderTags={renderTags}
    sx={sx}
    isOptionEqualToValue={isOptionEqualToValue}
    disableClearable={disableClearable}
    renderInput={(params) => (
      <TextField
        {...params}
        name={name}
        label={label}
        error={error}
        helperText={helperText}
        required={required}
        placeholder={placeholder}
      />
    )}
  />
);
