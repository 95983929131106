import { IEntityMainTableHeaderCell } from '@types';
import { TableHeaderCellAligment } from './table';

export enum EntityReportType {
  Plain = 'plain',
  Grouped = 'grouped',
}

export const EntityReportTypeLabel: Record<EntityReportType, string> = {
  [EntityReportType.Plain]: 'Plain table',
  [EntityReportType.Grouped]: 'Grouped report',
};

export const EntityReportTypeOptions = [
  { label: 'Plain table', value: EntityReportType.Plain },
  {
    label: 'Table with grouping and sub-totals',
    value: EntityReportType.Grouped,
  },
];

export enum EntityReportSource {
  Candidates = 'Candidates',
  Positions = 'JobOpenings',
}

export const EntityReportSourceLabel: Record<EntityReportSource, string> = {
  [EntityReportSource.Candidates]: 'Candidates',
  [EntityReportSource.Positions]: 'Positions',
};

export const EntityReportSourceOptions = [
  { label: 'Candidates', value: EntityReportSource.Candidates },
  {
    label: 'Positions',
    value: EntityReportSource.Positions,
  },
];

export enum EntityReportColumnType {
  Boolean = 'boolean',
  Text = 'text',
  Number = 'number',
  Date = 'date',
  Multipicklist = 'multipicklist',
  Picklist = 'picklist',
}

export enum EntityReportCandidateColumnGroup {
  Profile = 'Candidate details',
  TestTask = 'Test task',
}

export enum EntityReportFilterOperator {
  Is = 'is',
  IsNot = 'isNot',
  IsEmpty = 'isEmpty',
  IsNotEmpty = 'isNotEmpty',
  Contains = 'contains',
  NotContains = 'notContains',
  StartsWith = 'StartsWith',
  EndsWith = 'endsWith',
  HigherThan = 'higherThan',
  LowerThan = 'lowerThan',
  Checked = 'checked',
  NotChecked = 'notChecked',
  IsBefore = 'isBefore',
  IsAfter = 'isAfter',
  Between = 'between',
  NotBetween = 'notBetween',
  Yesterday = 'yesterday',
  TillYesterday = 'tillYesterday',
  Today = 'today',
  Tomorrow = 'tomorrow',
  StartingTomorrow = 'startingTomorrow',
  LastWeek = 'lastWeek',
  ThisWeek = 'thisWeek',
  NextWeek = 'nextWeek',
  LastMonth = 'lastMonth',
  ThisMonth = 'thisMonth',
  NextMonth = 'nextMonth',
  ThisYear = 'thisYear',
}

export const EntityReportFilterOperatorWithoutValue = [
  EntityReportFilterOperator.IsEmpty,
  EntityReportFilterOperator.IsNotEmpty,
  EntityReportFilterOperator.Checked,
  EntityReportFilterOperator.NotChecked,
  EntityReportFilterOperator.Yesterday,
  EntityReportFilterOperator.TillYesterday,
  EntityReportFilterOperator.Today,
  EntityReportFilterOperator.Tomorrow,
  EntityReportFilterOperator.StartingTomorrow,
  EntityReportFilterOperator.LastWeek,
  EntityReportFilterOperator.ThisWeek,
  EntityReportFilterOperator.NextWeek,
  EntityReportFilterOperator.LastMonth,
  EntityReportFilterOperator.ThisMonth,
  EntityReportFilterOperator.NextMonth,
  EntityReportFilterOperator.ThisYear,
];

export const EntityReportFilterOperatorByColumnType: Record<
  EntityReportColumnType,
  EntityReportFilterOperator[]
> = {
  [EntityReportColumnType.Number]: [
    EntityReportFilterOperator.Is,
    EntityReportFilterOperator.IsNot,
    EntityReportFilterOperator.IsEmpty,
    EntityReportFilterOperator.IsNotEmpty,
    EntityReportFilterOperator.LowerThan,
    EntityReportFilterOperator.HigherThan,
  ],
  [EntityReportColumnType.Boolean]: [
    EntityReportFilterOperator.Checked,
    EntityReportFilterOperator.NotChecked,
  ],
  [EntityReportColumnType.Text]: [
    EntityReportFilterOperator.Is,
    EntityReportFilterOperator.IsNot,
    EntityReportFilterOperator.IsEmpty,
    EntityReportFilterOperator.IsNotEmpty,
    EntityReportFilterOperator.Contains,
    EntityReportFilterOperator.NotContains,
    EntityReportFilterOperator.StartsWith,
    EntityReportFilterOperator.EndsWith,
  ],
  [EntityReportColumnType.Picklist]: [
    EntityReportFilterOperator.Is,
    EntityReportFilterOperator.IsNot,
    EntityReportFilterOperator.IsEmpty,
    EntityReportFilterOperator.IsNotEmpty,
    EntityReportFilterOperator.Contains,
    EntityReportFilterOperator.NotContains,
    EntityReportFilterOperator.StartsWith,
    EntityReportFilterOperator.EndsWith,
  ],
  [EntityReportColumnType.Multipicklist]: [
    EntityReportFilterOperator.Is,
    EntityReportFilterOperator.IsNot,
    EntityReportFilterOperator.IsEmpty,
    EntityReportFilterOperator.IsNotEmpty,
    EntityReportFilterOperator.Contains,
    EntityReportFilterOperator.NotContains,
    EntityReportFilterOperator.StartsWith,
    EntityReportFilterOperator.EndsWith,
  ],
  [EntityReportColumnType.Date]: [
    EntityReportFilterOperator.Is,
    EntityReportFilterOperator.IsNot,
    EntityReportFilterOperator.IsEmpty,
    EntityReportFilterOperator.IsNotEmpty,
    EntityReportFilterOperator.IsBefore,
    EntityReportFilterOperator.IsAfter,
    EntityReportFilterOperator.Between,
    EntityReportFilterOperator.NotBetween,
    EntityReportFilterOperator.Yesterday,
    EntityReportFilterOperator.TillYesterday,
    EntityReportFilterOperator.Today,
    EntityReportFilterOperator.Tomorrow,
    EntityReportFilterOperator.StartingTomorrow,
    EntityReportFilterOperator.LastWeek,
    EntityReportFilterOperator.ThisWeek,
    EntityReportFilterOperator.NextWeek,
    EntityReportFilterOperator.LastMonth,
    EntityReportFilterOperator.ThisMonth,
    EntityReportFilterOperator.NextMonth,
    EntityReportFilterOperator.ThisYear,
  ],
};

export const EntityReportFilterOperatorLabel: Record<
  EntityReportFilterOperator,
  string
> = {
  [EntityReportFilterOperator.Is]: 'is',
  [EntityReportFilterOperator.IsNot]: "isn't",
  [EntityReportFilterOperator.IsEmpty]: 'is empty',
  [EntityReportFilterOperator.IsNotEmpty]: 'is not empty',
  [EntityReportFilterOperator.Contains]: 'contains',
  [EntityReportFilterOperator.NotContains]: "doesn't contain",
  [EntityReportFilterOperator.StartsWith]: 'starts with',
  [EntityReportFilterOperator.EndsWith]: 'ends with',
  [EntityReportFilterOperator.LowerThan]: 'lower than',
  [EntityReportFilterOperator.HigherThan]: 'higher than',
  [EntityReportFilterOperator.Checked]: 'checked',
  [EntityReportFilterOperator.NotChecked]: 'not checked',
  [EntityReportFilterOperator.IsBefore]: 'is before',
  [EntityReportFilterOperator.IsAfter]: 'is after',
  [EntityReportFilterOperator.Between]: 'between',
  [EntityReportFilterOperator.NotBetween]: 'not between',
  [EntityReportFilterOperator.Yesterday]: 'Yesterday',
  [EntityReportFilterOperator.TillYesterday]: 'Till Yesterday',
  [EntityReportFilterOperator.Today]: 'Today',
  [EntityReportFilterOperator.Tomorrow]: 'Tomorrow',
  [EntityReportFilterOperator.StartingTomorrow]: 'Starting Tomorrow',
  [EntityReportFilterOperator.LastWeek]: 'Last Week',
  [EntityReportFilterOperator.ThisWeek]: 'This Week',
  [EntityReportFilterOperator.NextWeek]: 'Next Week',
  [EntityReportFilterOperator.LastMonth]: 'Last Month',
  [EntityReportFilterOperator.ThisMonth]: 'This Month',
  [EntityReportFilterOperator.NextMonth]: 'Next Month',
  [EntityReportFilterOperator.ThisYear]: 'This Year',
};

export enum EntityReportsGroupingColumn {
  NONE = 'None',
  Folder = 'folder',
}

export const EntityReportsGroupOptions = [
  { label: 'None', value: EntityReportsGroupingColumn.NONE },
  { label: 'Folder', value: EntityReportsGroupingColumn.Folder },
];

export enum EntityReportsSortingColumn {
  NONE = 'None',
  Name = 'name',
  Folder = 'folder',
  Source = 'source',
  Type = 'type',
  Columns = 'columnsAmount',
  Owner = 'owner',
  UpdatedAt = 'updatedAt',
  CreatedAt = 'createdAt',
}

export const tableHeadersEntityReports: IEntityMainTableHeaderCell[] = [
  {
    label: 'Report',
    value: EntityReportsSortingColumn.Name,
    width: 400,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Folder',
    value: EntityReportsSortingColumn.Folder,
    width: 150,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Scope',
    value: EntityReportsSortingColumn.Source,
    width: 150,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Type',
    value: EntityReportsSortingColumn.Type,
    width: 150,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Columns',
    value: EntityReportsSortingColumn.Columns,
    width: 150,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Owner',
    value: EntityReportsSortingColumn.Owner,
    width: 250,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Updated At',
    value: EntityReportsSortingColumn.UpdatedAt,
    width: 300,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: 'Created At',
    value: EntityReportsSortingColumn.CreatedAt,
    width: 300,
    align: TableHeaderCellAligment.Left,
    isSortable: true,
  },
  {
    label: '',
    value: null,
    width: 400,
    align: TableHeaderCellAligment.Left,
    isSortable: false,
  },
];

export enum EntityReportStep {
  Type = 'Type',
  Columns = 'Columns',
  Grouping = 'Grouping',
  Filters = 'Filters',
  Save = 'Save',
}

export const EntityReportStepsOrdered = [
  EntityReportStep.Type,
  EntityReportStep.Columns,
  EntityReportStep.Grouping,
  EntityReportStep.Filters,
  EntityReportStep.Save,
];
