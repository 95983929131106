import { authSliceReducer, authStoreKey } from './auth';
import {
  candidateDetailsSliceReducer,
  candidateDetailsStoreKey,
} from './candidateDetails';
import { candidatesSliceReducer, candidatesStoreKey } from './candidates';
import {
  jobOpeningDetailsSliceReducer,
  jobOpeningDetailsStoreKey,
} from './jobOpeningDetails';
import { jobOpeningsSliceReducer, jobOpeningsStoreKey } from './jobOpenings';
import { snackbarSliceReducer, snackbarStoreKey } from './snackbar';
import { optionsSliceReducer, optionsStoreKey } from './options';
import {
  actionReportSliceReducer,
  actionReportStoreKey,
} from './action-report';
import { submissionSliceReducer, submissionStoreKey } from './submission';
import { pageTitleSliceReducer, pageTitleStoreKey } from './pageTitle';
import {
  entityReportsStoreKey,
  entityReportsSliceReducer,
} from './entityReports';

// Create the root reducer independently to obtain the RootState type
export const rootReducer = {
  [authStoreKey]: authSliceReducer,
  [jobOpeningsStoreKey]: jobOpeningsSliceReducer,
  [jobOpeningDetailsStoreKey]: jobOpeningDetailsSliceReducer,
  [candidateDetailsStoreKey]: candidateDetailsSliceReducer,
  [candidatesStoreKey]: candidatesSliceReducer,
  [snackbarStoreKey]: snackbarSliceReducer,
  [optionsStoreKey]: optionsSliceReducer,
  [actionReportStoreKey]: actionReportSliceReducer,
  [submissionStoreKey]: submissionSliceReducer,
  [pageTitleStoreKey]: pageTitleSliceReducer,
  [entityReportsStoreKey]: entityReportsSliceReducer,
};
