import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@redux/hooks';

import { alpha, Box, Button, IconButton, Typography } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { ReactComponent as GSheetIcon } from '@assets/google-sheet-icon.svg';
import { Flex } from '@components';

import { exportEntityReport } from '@redux/entityReports';

import { AppRoutes } from '@constants';

export const EntityReportDataHeader: React.FC<{
  entityReportId: string;
  entityReportName: string;
  recordsAmount: number;
  isLoading: boolean;
  isPerformingAction: boolean;
  onShareReportClick: () => void;
}> = ({
  entityReportId,
  entityReportName,
  recordsAmount,
  isLoading,
  isPerformingAction,
  onShareReportClick,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const onNavigateBackClick = useCallback(() => {
    if (location.state && location.state.from) {
      navigate(location.state.from);
    } else {
      navigate(`/${AppRoutes.ENTITY_REPORTS}`);
    }
  }, [location]);
  const onEditButtonClick = useCallback(
    () =>
      navigate(`/${AppRoutes.ENTITY_REPORTS}/${entityReportId}/edit`, {
        state: { from: `${location.pathname}${location.search}` },
      }),
    [entityReportId, location],
  );
  const onExportReportClick = useCallback(() => {
    dispatch(exportEntityReport({ entityReportId, entityReportName }));
  }, [entityReportId, entityReportName]);

  return (
    <Flex gap={2} justifyContent="space-between" p={2}>
      <Flex gap={1}>
        <IconButton
          size="large"
          aria-label="go back"
          onClick={onNavigateBackClick}
          sx={(theme) => ({
            backgroundColor: theme.palette.brand.accent,
            color: theme.palette.text.invertedPrimary,
            padding: '0.625rem',
            borderRadius: '0.5rem',
            '&:hover': {
              backgroundColor: alpha(theme.palette.brand.accent, 0.7),
            },
          })}
        >
          <ArrowBackRoundedIcon />
        </IconButton>
        {!isLoading && (
          <Box>
            <Typography variant="h3" color="text.primary" noWrap>
              {entityReportName}
            </Typography>
            <Typography variant="caption" color="text.secondary" noWrap>
              {recordsAmount} records
            </Typography>
          </Box>
        )}
      </Flex>
      <Flex gap={1} justifyContent="end">
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={onEditButtonClick}
          startIcon={<EditIcon fontSize="small" />}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={onShareReportClick}
          startIcon={<PersonAddIcon fontSize="small" />}
        >
          Share
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={onExportReportClick}
          startIcon={<GSheetIcon width="18" />}
          disabled={isPerformingAction}
        >
          Export
        </Button>
      </Flex>
    </Flex>
  );
};
