import { Box, SxProps, Theme, Typography } from '@mui/material';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import { JobOpeningFormMode } from '@constants';

interface IZohoDisclaimerProps {
  continerStyle?: SxProps<Theme> | undefined;
  mode?: JobOpeningFormMode;
}

export const ZohoPositionDisclaimer = ({
  continerStyle,
  mode,
}: IZohoDisclaimerProps) => {
  return (
    <Box
      position="sticky"
      bottom={0}
      display="flex"
      justifyItems="center"
      alignItems="center"
      gap={2}
      sx={{
        ...continerStyle,
      }}
      borderRadius={1}
      padding={'16px'}
      marginX={1}
      zIndex={10}
    >
      <SyncRoundedIcon fontSize="small" color="secondary" />
      <Typography color="secondary">
        {mode === JobOpeningFormMode.Edit ? `Edited` : `New`} positions may
        appear with some delay, data is synced with Zoho once an hour
      </Typography>
    </Box>
  );
};
