import { uniq } from 'lodash';

import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { InterviewStepValueValue } from '@constants';

const INTERVIEW_STEPS = [
  InterviewStepValueValue.TI,
  InterviewStepValueValue.TLIntro,
  InterviewStepValueValue.Submission,
  InterviewStepValueValue.IncludeTTTinSubmission,
  InterviewStepValueValue.CI,
  InterviewStepValueValue.Reports,
];

export const PositionInterviewStepsField: React.FC<{
  value: string[] | null;
  name?: string;
  required?: boolean | undefined;
  touched?: boolean;
  error?: string | undefined;
  onChange: (newValue: any) => void;
}> = ({ value, error, touched, onChange }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '30px',
        marginTop: '-40px',
        ['@media (max-width: 890px)']: {
          marginTop: '30px',
          marginLeft: 0,
        },
      }}
    >
      <Typography
        variant="h4"
        mb={1}
        color={touched && error ? 'text.danger' : 'text.primary'}
      >
        Interview steps *
      </Typography>
      {INTERVIEW_STEPS.map((step) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={value?.includes(step) || false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.checked) {
                    onChange(uniq([...(value || []), event.target.name]));
                  } else {
                    onChange(
                      value?.filter((e) => e !== event.target.name) || [],
                    );
                  }
                }}
              />
            }
            key={step}
            name={step}
            label={step}
            sx={{ marginBottom: -1 }}
          />
        );
      })}
    </Box>
  );
};
