import { useCallback, useEffect, useMemo, useState } from 'react';
import { groupBy, orderBy } from 'lodash';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { ReactComponent as CheckboxCheckIcon } from 'assets/checkbox-check.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/checkbox-checked.svg';

import { CountryFlag } from '@components';

import { optionsSelectors, fetchJobOpeningCountries } from '@redux/options';

import {
  CityTier,
  CountryRegionOrder,
  JobOpeningSourceOptions,
  JopOpeningCityMapTierToShortValue,
  PositionType,
} from '@constants';
import { checkUserCanViewDetailedJOLocation, isStatusIdle } from '@utils';
import { ICity, ICountry, JobOpeningFormValues } from '@types';
import { authSelectors } from '@redux/auth';

const CUSTOM_TIER = 'Custom';

const StyledCheckbox = styled(Checkbox)(() => ({
  '.MuiTouchRipple-root': {
    display: 'none',
  },
  '&:hover': {
    backgroundColor: 'transparent !important',
  },
}));

StyledCheckbox.defaultProps = {
  icon: <CheckboxCheckIcon />,
  checkedIcon: <CheckboxCheckedIcon />,
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    margin: '0 0.35rem',
    padding: '0 0.15rem',
    marginBottom: '-0.5rem',
    backgroundColor: theme.palette.common.white,
    display: 'inline-block',
    width: 'fit-content',
  },
  '& .MuiFormGroup-root': {
    backgroundColor: theme.palette.highlight.neutral,
    padding: '1rem 0.5rem',
    borderRadius: '0.5rem',
  },
}));

export const PositionLocationField: React.FC<{
  value: string[] | null;
  onChange: (newValue: any) => void;
  otherValues?: JobOpeningFormValues;
}> = ({ value, onChange, otherValues }) => {
  const dispatch = useAppDispatch();

  const [showCitySelection, setShowCitySelection] = useState<boolean>(false);

  const { data, apiStatus } = useAppSelector(
    optionsSelectors.getJobOpeningCountries,
  );
  const profile = useAppSelector(authSelectors.getProfileData);

  // Fetch countries if API status is idle
  useEffect(() => {
    if (isStatusIdle(apiStatus)) dispatch(fetchJobOpeningCountries());
  }, [apiStatus, dispatch]);

  const isBillablePosition = useMemo(() => {
    const currentSourceOption = JobOpeningSourceOptions.find(
      (source) => source.value === otherValues?.Position_type,
    );
    return currentSourceOption?.positionType === PositionType.BILLABLE;
  }, [otherValues]);

  // Check if current path is a job opening create path
  const showDetailedJOLocation = useMemo(
    () => checkUserCanViewDetailedJOLocation(profile),
    [profile],
  );

  // Memoize country, city, and options
  const { countries, cities, countriesOptions } = useMemo(() => {
    // Sort countries based on region order
    const orderedCountries = orderBy(
      data,
      [
        (country) => CountryRegionOrder.indexOf(country.region),
        (country) => country.value.toLowerCase(),
      ],
      ['asc', 'asc'],
    );

    // Flatten all cities from all countries
    const allCities = orderedCountries.flatMap((country) => country.cities);

    // Group countries by region for easier access
    const groupedCountriesOptions = groupBy(orderedCountries, 'region');

    return {
      countries: orderedCountries,
      cities: allCities,
      countriesOptions: groupedCountriesOptions,
    };
  }, [data]);

  // Track selected values to optimize selection
  const selectedValuesSet = useMemo(() => new Set(value), [value]);

  // Filter selected countries and associated cities
  const countriesSelected = useMemo(
    () =>
      countries.filter(
        (country) =>
          selectedValuesSet.has(country.value) ||
          country.cities.some((city) => selectedValuesSet.has(city.value)),
      ),
    [selectedValuesSet, countries],
  );

  // Extract values of selected countries to a set
  const selectedCountriesValuesSet = useMemo(
    () => new Set(countriesSelected.map((country) => country.value)),
    [countriesSelected],
  );

  // Filter cities from selected countries and sort them alphabetically
  const citiesOptions = useMemo(
    () =>
      cities
        .filter((city) =>
          countriesSelected.some(
            (country) => country.countryCode === city.countryCode,
          ),
        )
        .sort((a, b) => a.value.localeCompare(b.value)),
    [cities, countriesSelected],
  );

  // Calculate selected tier based on current selection
  const selectedTier = useMemo(() => {
    if (!selectedValuesSet.size) return null;

    const allCitiesByTier = groupBy(citiesOptions, 'tier');
    const allTier1Cities = allCitiesByTier[CityTier.TIER_1] || [];
    const allTier2Cities = allCitiesByTier[CityTier.TIER_2] || [];

    const areAllTier1Selected = allTier1Cities.every((city) =>
      selectedValuesSet.has(city.value),
    );
    const areAnyTier2Selected = allTier2Cities.some((city) =>
      selectedValuesSet.has(city.value),
    );
    const areAllTier2Selected = allTier2Cities.every((city) =>
      selectedValuesSet.has(city.value),
    );
    const areAllRemoteCountriesSelected = countriesSelected.every((country) =>
      selectedValuesSet.has(country.value),
    );

    if (areAllTier1Selected && !areAnyTier2Selected) return CityTier.TIER_1;
    if (
      areAllTier1Selected &&
      areAllTier2Selected &&
      !areAllRemoteCountriesSelected
    )
      return CityTier.TIER_2;
    if (
      areAllTier1Selected &&
      areAllTier2Selected &&
      areAllRemoteCountriesSelected
    )
      return CityTier.TIER_3;

    return CUSTOM_TIER;
  }, [selectedValuesSet, citiesOptions, countriesSelected]);

  // Update the selected values based on checkbox interaction
  const updateSelection = useCallback(
    (items: string[], isChecked: boolean, itemsToRemove?: string[]) => {
      const nextValue = new Set(selectedValuesSet);
      items.forEach((item) =>
        isChecked ? nextValue.add(item) : nextValue.delete(item),
      );
      itemsToRemove?.forEach((item) => nextValue.delete(item));
      onChange(nextValue.size ? Array.from(nextValue) : null);
    },
    [selectedValuesSet, onChange],
  );

  // Get TIER_1 and TIER_2 cities for given country codes, fallback to country value if no tier cities exist
  const getTier1andTier2Cities = useCallback(
    (countryCodes: string[], checked: boolean) =>
      countries
        .filter((country) => countryCodes.includes(country.countryCode))
        .flatMap((country) => {
          if (isBillablePosition && checked) {
            return [country.value];
          }
          const tierCities = country.cities.filter(
            (city) =>
              city.tier === CityTier.TIER_1 || city.tier === CityTier.TIER_2,
          );
          return tierCities.length > 0
            ? tierCities.map((city) => city.value)
            : [country.value];
        }),
    [countries, isBillablePosition],
  );

  // Handle region selection
  const handleRegionSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
      const regionCountries = countriesOptions[name] || [];
      const tierCities = getTier1andTier2Cities(
        regionCountries.map((rCountry) => rCountry.countryCode),
        checked,
      );
      updateSelection(tierCities, checked);
    },
    [countriesOptions, updateSelection, getTier1andTier2Cities],
  );

  // Handle country selection
  const handleCountrySelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
      const country = countries.find((country) => country.countryCode === name);
      if (country) {
        const tierCities = getTier1andTier2Cities(
          [country.countryCode],
          checked,
        );
        updateSelection(
          checked ? tierCities : [...tierCities, country.value],
          checked,
        );
      }
    },
    [countries, updateSelection, getTier1andTier2Cities],
  );

  // Handle city selection
  const handleCitySelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
      const itemsToRemove: string[] = [];
      const city = cities.find((city) => city.value === name);
      const countryValue = countries.find(
        (country) => country.countryCode === city?.countryCode,
      )?.value;
      if (countryValue) itemsToRemove.push(countryValue);

      updateSelection([name], checked, itemsToRemove);
    },
    [countries, cities, updateSelection],
  );

  // Handle remote selection
  const handleRemoteSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
      const itemsToRemove: string[] = [];
      const country = countries.find((country) => country.value === name);
      const filteredCities = cities.reduce((acc, curr) => {
        if (curr.countryCode === country?.countryCode) {
          acc.push(curr.value);
        }
        return acc;
      }, [] as string[]);
      if (filteredCities.length) itemsToRemove.push(...filteredCities);
      updateSelection([name], checked, itemsToRemove);
    },
    [countries, cities, updateSelection],
  );

  // Handle tier radio button change
  const handleTierRadioChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedTier = event.target.value as CityTier | typeof CUSTOM_TIER;
      if (selectedTier === CUSTOM_TIER) return;

      const allCitiesByTier = groupBy(citiesOptions, 'tier');
      const allTier1Cities = allCitiesByTier[CityTier.TIER_1] || [];
      const allTier2Cities = allCitiesByTier[CityTier.TIER_2] || [];

      const newSelectedValuesSet = new Set<string>();
      if (selectedTier === CityTier.TIER_1) {
        allTier1Cities.forEach((city) => newSelectedValuesSet.add(city.value));
      } else if (selectedTier === CityTier.TIER_2) {
        allTier1Cities.forEach((city) => newSelectedValuesSet.add(city.value));
        allTier2Cities.forEach((city) => newSelectedValuesSet.add(city.value));
      } else if (selectedTier === CityTier.TIER_3) {
        allTier1Cities.forEach((city) =>
          newSelectedValuesSet.delete(city.value),
        );
        allTier2Cities.forEach((city) =>
          newSelectedValuesSet.delete(city.value),
        );
        countriesSelected.forEach((country) =>
          newSelectedValuesSet.add(country.value),
        );
      }

      onChange(
        newSelectedValuesSet.size ? Array.from(newSelectedValuesSet) : null,
      );
    },
    [onChange, citiesOptions, countriesSelected],
  );

  const RegionCheckbox = ({
    region,
    regionCountries,
  }: {
    region: string;
    regionCountries: ICountry[];
  }) => {
    const isChecked = useMemo(
      () =>
        regionCountries.every((country) =>
          selectedCountriesValuesSet.has(country.value),
        ),
      [regionCountries, selectedCountriesValuesSet],
    );

    return (
      <FormControlLabel
        label={`All in ${region}`}
        control={
          <StyledCheckbox
            checked={isChecked}
            onChange={handleRegionSelect}
            name={region}
          />
        }
      />
    );
  };

  // Extracted sub-component for an individual country checkbox with flag and title
  const CountryCheckbox = ({ country }: { country: ICountry }) => (
    <FormControlLabel
      key={country.countryCode}
      label={
        <>
          <CountryFlag country={country.value} />
          {country.title}
        </>
      }
      control={
        <StyledCheckbox
          checked={selectedCountriesValuesSet.has(country.value)}
          onChange={handleCountrySelect}
          name={country.countryCode}
          sx={{ padding: '4px 9px' }}
        />
      }
    />
  );

  // Extracted sub-component for a city checkbox with tier indicator
  const CityCheckbox = ({ city }: { city: ICity }) => (
    <FormControlLabel
      key={city.value}
      label={
        <Typography variant="body2">
          {city.value}
          {showDetailedJOLocation && (
            <Box
              component="span"
              sx={{
                fontSize: '0.688rem',
                color: 'text.secondary',
                marginLeft: '0.25rem',
              }}
            >
              {JopOpeningCityMapTierToShortValue[city.tier]}
            </Box>
          )}
        </Typography>
      }
      control={
        <StyledCheckbox
          checked={selectedValuesSet.has(city.value)}
          onChange={handleCitySelect}
          name={city.value}
        />
      }
    />
  );

  // Extracted sub-component for a remote country checkbox
  const RemoteCountryCheckbox = ({ country }: { country: ICountry }) => (
    <FormControlLabel
      key={country.value}
      label={
        <Typography variant="body2">
          {country.value} remote
          {showDetailedJOLocation && (
            <Box
              component="span"
              sx={{
                fontSize: '0.688rem',
                color: 'text.secondary',
                marginLeft: '0.25rem',
              }}
            >
              {JopOpeningCityMapTierToShortValue[CityTier.TIER_3]}
            </Box>
          )}
        </Typography>
      }
      control={
        <StyledCheckbox
          checked={selectedValuesSet.has(country.value)}
          onChange={handleRemoteSelect}
          name={country.value}
        />
      }
    />
  );

  return (
    <Stack gap={3}>
      {showDetailedJOLocation && (
        <Box>
          <StyledFormControl disabled={!Boolean(selectedValuesSet.size)}>
            <FormLabel>Tier</FormLabel>
            <RadioGroup
              row
              name="row-radio-buttons-group"
              value={selectedTier}
              onChange={handleTierRadioChange}
            >
              {[
                CityTier.TIER_1,
                CityTier.TIER_2,
                CityTier.TIER_3,
                CUSTOM_TIER,
              ].map((tier) => (
                <FormControlLabel
                  key={tier}
                  value={tier}
                  control={<Radio />}
                  label={tier}
                />
              ))}
            </RadioGroup>
          </StyledFormControl>
        </Box>
      )}
      <Stack direction="row" gap={3}>
        {Object.entries(countriesOptions).map(([region, regionCountries]) => (
          <Stack key={region}>
            {regionCountries.length > 1 && (
              <RegionCheckbox
                region={region}
                regionCountries={regionCountries}
              />
            )}
            {regionCountries.map((country) => (
              <CountryCheckbox key={country.countryCode} country={country} />
            ))}
          </Stack>
        ))}
      </Stack>
      {(showCitySelection || showDetailedJOLocation) && (
        <>
          {citiesOptions.length ? (
            <Stack gap={1}>
              <Typography color="secondary" fontSize="0.75rem">
                Cities
              </Typography>
              {!showDetailedJOLocation && (
                <Stack
                  direction="row"
                  gap="0.75rem"
                  padding="0.875rem 1rem"
                  bgcolor="highlight.neutral"
                  borderRadius="0.25rem"
                >
                  <LocationOnOutlinedIcon color="secondary" />
                  <Typography variant="body2" marginY="auto">
                    Please specify cities only when it’s “a must” for the
                    position requirements
                  </Typography>
                </Stack>
              )}
              <Stack direction="row" flexWrap="wrap">
                {citiesOptions.map((city) => (
                  <CityCheckbox key={city.value} city={city} />
                ))}
              </Stack>
            </Stack>
          ) : null}

          {countriesSelected.length ? (
            <Stack gap={1}>
              <Typography color="secondary" fontSize="0.75rem">
                Remote
              </Typography>
              <Stack direction="row" flexWrap="wrap">
                {countriesSelected.map((country) => (
                  <RemoteCountryCheckbox
                    key={country.value}
                    country={country}
                  />
                ))}
              </Stack>
            </Stack>
          ) : null}
        </>
      )}
      {!showDetailedJOLocation && !showCitySelection && (
        <Stack direction="row" gap="0.5rem" alignItems="center">
          <Button
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<ApartmentIcon />}
            onClick={() => setShowCitySelection((prev) => !prev)}
          >
            Specify city
          </Button>
          <Typography color="secondary" fontSize="0.75rem">
            Optional
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
