import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  MobileStepper,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { EntityReportStep, EntityReportType } from '@constants';
import { getEntityReportsSteps } from '@utils';

export const EntityReportFormFooter: React.FC<{
  type: EntityReportType | null;
  activeStep: EntityReportStep;
  isPerformingAction: boolean;
  onPreviousClick: () => void;
  onNextClick: () => void;
  onSubmitClick: () => void;
}> = ({
  type,
  activeStep,
  isPerformingAction,
  onPreviousClick,
  onNextClick,
  onSubmitClick,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const stepsOrder = getEntityReportsSteps(type);
  const isFirstStep = stepsOrder[0] === activeStep;
  const isLastStep = stepsOrder.at(-1) === activeStep;

  return (
    <Box width="100%">
      {isMobile ? (
        <MobileStepper
          variant="text"
          steps={stepsOrder.length}
          activeStep={stepsOrder.indexOf(activeStep)}
          nextButton={
            <Button
              variant="contained"
              size="small"
              onClick={isLastStep ? onSubmitClick : onNextClick}
              disabled={isPerformingAction}
            >
              {isLastStep ? 'Submit' : 'Next'}
            </Button>
          }
          backButton={
            <Button
              variant="contained"
              color="secondary"
              onClick={onPreviousClick}
              disabled={isPerformingAction || isFirstStep}
            >
              Previous
            </Button>
          }
        />
      ) : (
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" gap={1}>
            {!isFirstStep && (
              <Button
                variant="contained"
                color="secondary"
                onClick={onPreviousClick}
              >
                Previous
              </Button>
            )}
            {isLastStep ? (
              <>
                <Button
                  variant="contained"
                  onClick={onSubmitClick}
                  disabled={isPerformingAction}
                >
                  Save
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                onClick={onNextClick}
                disabled={isPerformingAction}
                endIcon={<NavigateNextIcon />}
              >
                Next
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
