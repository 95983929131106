import { Typography, Grid, Paper, Stack } from '@mui/material';
import { JobOpeningField } from '@components';

import { JobOpeningFormik } from '@types';

export const LocationDetails: React.FC<{
  formik: JobOpeningFormik;
}> = ({ formik }) => {
  const locationsError =
    formik.touched.Location_Tier_Outreach &&
    formik.errors.Location_Tier_Outreach;

  return (
    <Paper elevation={1} sx={{ mb: '1px', pt: 4, pb: 6, paddingX: 5 }}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h2"
            color={locationsError ? 'text.danger' : 'text.primary'}
            marginBottom={locationsError ? 0 : '15px'}
          >
            Locations
          </Typography>
          {locationsError && (
            <Typography variant="body2" color="text.danger" marginBottom="15px">
              {locationsError}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid container mb={2}>
        <Grid item>
          <JobOpeningField
            field="Location_Tier_Outreach"
            value={formik.values.Location_Tier_Outreach}
            touched={formik.touched.Location_Tier_Outreach}
            error={formik.errors.Location_Tier_Outreach}
            onChange={(val) =>
              formik.setFieldValue('Location_Tier_Outreach', val || null)
            }
            onBlur={() => formik.setFieldTouched('Location_Tier_Outreach')}
            otherValues={formik.values}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Stack direction="row" sx={{ maxWidth: '480px' }} gap={'0.5rem'}>
            <JobOpeningField
              field="Timezone_Requirements"
              value={formik.values.Timezone_Requirements}
              touched={formik.touched.Timezone_Requirements}
              error={formik.errors.Timezone_Requirements}
              onChange={(val) =>
                formik.setFieldValue('Timezone_Requirements', val || null)
              }
              onBlur={() => formik.setFieldTouched('Timezone_Requirements')}
            />
            <Typography color="secondary" fontSize={'0.75rem'} margin={'auto'}>
              Optional
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};
