import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { format } from 'date-fns';

import { Box, Link, Stack, Typography } from '@mui/material';
import { ICandidateSubmission } from '@types';

import { displayDateTimeInTimezone, isClientInterviewDone } from '@utils';
import {
  ResolutionColorBySubmissionStatus,
  SubmissionStatus,
} from '@constants';

const SubmissionResolutionMap: Record<
  SubmissionStatus,
  string | JSX.Element | ((data?: ICandidateSubmission) => JSX.Element)
> = {
  [SubmissionStatus.Draft]: 'Draft',
  [SubmissionStatus.CallRequested]: 'Approved for an interview',
  [SubmissionStatus.CallCancelled]: 'Interview cancelled',
  [SubmissionStatus.CallScheduled]: (data) => (
    <>
      {isClientInterviewDone(
        data?.interview?.scheduledSlot?.[0] || null,
        data?.interview?.clientTimezone || null,
      )
        ? 'Interview feedback is pending'
        : 'Interview scheduled'}
    </>
  ),
  [SubmissionStatus.CallPassed]: 'Passed the interview',
  [SubmissionStatus.CallFailed]: (data) => (
    <>
      CI failed:{' '}
      <Box component="span" fontWeight={400}>
        {(data?.interview?.result?.rejectionReason || [])?.join(', ')}
      </Box>
    </>
  ),
  [SubmissionStatus.Rejected]: (data) => (
    <>
      Rejected:{' '}
      <Box component="span" fontWeight={400}>
        {(data?.clientFeedback?.rejectionReason || [])?.join(', ')}
      </Box>
    </>
  ),
  [SubmissionStatus.Submitted]: 'Submitted',
  [SubmissionStatus.Hired]: 'Hired',
};

export const ClientFeeback: React.FC<{
  submission: ICandidateSubmission;
}> = ({ submission }) => {
  const statusLabel = useMemo(() => {
    const label = SubmissionResolutionMap[submission.status];
    return typeof label === 'function' ? label(submission) : label;
  }, [submission]);

  const date = useMemo(() => {
    switch (submission.status) {
      case SubmissionStatus.CallRequested:
        return submission.interview?.requestedSlot?.[0].start;
      case SubmissionStatus.CallScheduled:
        return submission.interview?.scheduledSlot?.[0].start;
      case SubmissionStatus.CallCancelled:
        return submission.interview?.cancellation?.cancelledAt;
      case SubmissionStatus.CallPassed:
      case SubmissionStatus.CallFailed:
        return submission.interview?.result?.providedAt;
      default:
        return submission.clientFeedback?.providedAt;
    }
  }, [submission]);

  const isCallCancelled = submission.status === SubmissionStatus.CallCancelled;

  if (!submission.clientFeedback?.resolution) return null;

  return (
    <Stack
      sx={{
        padding: {
          xs: '1rem',
          sm: '0.75rem',
        },
        mx: {
          xs: '-1rem',
          sm: '-0.5rem',
        },
        mt: '-0.5rem',
      }}
    >
      <Typography variant="body1" mb={1}>
        Client feedback
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        padding="0.875rem 1.5rem 1rem"
        borderRadius="0.25rem"
        bgcolor={ResolutionColorBySubmissionStatus[submission.status]}
      >
        <Typography variant="body2" color="common.white" fontWeight={600}>
          {statusLabel}
        </Typography>
        {date && (
          <Typography
            variant="body2"
            color="common.white"
            fontWeight={300}
            sx={{ textDecoration: isCallCancelled ? 'line-through' : 'none' }}
          >
            {format(new Date(date), 'EEE, MMM dd, hh:mm a')}
          </Typography>
        )}
      </Stack>
      <Box
        sx={(theme) => ({
          background: theme.palette.background.warningFill,
          padding: '24px',
          borderRadius: '8px',
        })}
      >
        <Box display="flex" gap={2} flexDirection="column">
          {!!submission.interview?.result && (
            <Stack gap={0.5}>
              <Typography variant="body2" color="text.secondary">
                Interviewed by {submission.interview?.result.reviewer?.name} on{' '}
                {dayjs(submission.interview?.result.providedAt).format(
                  'MMM DD',
                )}
              </Typography>
              <Typography variant="body1" whiteSpace="pre-line">
                {submission.interview?.result?.feedback}
              </Typography>
            </Stack>
          )}
          {!!submission.interview?.cancellation && (
            <Stack gap={0.5}>
              <Typography variant="body2" color="text.secondary">
                Interview cancelled by{' '}
                {submission.interview?.cancellation.cancelledBy?.name} on{' '}
                {dayjs(submission.interview?.cancellation.cancelledAt).format(
                  'MMM DD',
                )}
              </Typography>
              <Typography variant="body1" whiteSpace="pre-line">
                {submission.interview?.cancellation?.reason}
              </Typography>
            </Stack>
          )}
          {!!submission.interview?.scheduledSlot?.length && (
            <Stack gap={0.5}>
              <Typography variant="body2" color="text.secondary">
                Scheduled interview slot
              </Typography>
              {submission.interview.scheduledSlot.map((slot, index) => (
                <>
                  <Typography
                    key={`slot_${index}`}
                    variant="body1"
                    color="text.primary"
                  >
                    Client time (UTC
                    {submission.interview?.clientTimezone}):{' '}
                    <strong>
                      {`${displayDateTimeInTimezone(
                        slot.start,
                        submission.interview?.clientTimezone || '',
                      ).format(
                        'ddd, MMM DD, hh:mm a',
                      )} - ${displayDateTimeInTimezone(
                        slot.end,
                        submission.interview?.clientTimezone || '',
                      ).format('hh:mm a')}`}
                    </strong>
                  </Typography>
                  <Typography
                    key={`slot_${index}`}
                    variant="body1"
                    color="text.primary"
                  >
                    Candidate time (UTC
                    {submission.interview?.candidateTimezone}):{' '}
                    <strong>
                      {`${displayDateTimeInTimezone(
                        slot.start,
                        submission.interview?.candidateTimezone || '',
                      ).format(
                        'ddd, MMM DD, hh:mm a',
                      )} - ${displayDateTimeInTimezone(
                        slot.end,
                        submission.interview?.candidateTimezone || '',
                      ).format('hh:mm a')}, UTC ${
                        submission.interview?.clientTimezone
                      }`}
                    </strong>
                  </Typography>
                </>
              ))}
            </Stack>
          )}
          {!!submission.interview?.clientCalendar && (
            <Stack gap={0.5}>
              <Typography variant="body2" color="text.secondary">
                Client calendar
              </Typography>
              <Typography variant="body1" color="text.primary">
                {submission.interview.clientCalendar}
              </Typography>
            </Stack>
          )}
          {!!submission.interview?.requestedSlot?.length && (
            <Stack gap={0.5}>
              <Typography variant="body2" color="text.secondary">
                Requested interview slots
              </Typography>
              {submission.interview.requestedSlot.map((slot, index) => (
                <>
                  <Typography
                    key={`slot_${index}`}
                    variant="body1"
                    color="text.primary"
                  >
                    Client time (UTC
                    {submission.interview?.clientTimezone}):{' '}
                    <strong>
                      {`${displayDateTimeInTimezone(
                        slot.start,
                        submission.interview?.clientTimezone || '',
                      ).format(
                        'ddd, MMM DD, hh:mm a',
                      )} - ${displayDateTimeInTimezone(
                        slot.end,
                        submission.interview?.clientTimezone || '',
                      ).format('hh:mm a')}`}
                    </strong>
                  </Typography>
                  <Typography
                    key={`slot_${index}`}
                    variant="body1"
                    color="text.primary"
                  >
                    Candidate time (UTC
                    {submission.interview?.candidateTimezone}):{' '}
                    <strong>
                      {`${displayDateTimeInTimezone(
                        slot.start,
                        submission.interview?.candidateTimezone || '',
                      ).format(
                        'ddd, MMM DD, hh:mm a',
                      )} - ${displayDateTimeInTimezone(
                        slot.end,
                        submission.interview?.candidateTimezone || '',
                      ).format('hh:mm a')}`}
                    </strong>
                  </Typography>
                </>
              ))}
            </Stack>
          )}
          {!!submission.clientFeedback.feedback && (
            <Stack gap={0.5}>
              <Typography variant="body2" color="text.secondary">
                Feedback
              </Typography>
              <Typography
                variant="body1"
                whiteSpace="pre-line"
                sx={{
                  wordBreak: 'break-word',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {submission.clientFeedback.feedback}
              </Typography>
            </Stack>
          )}

          <Stack gap={0.5}>
            <Typography variant="body1" color="text.primary">
              {submission.clientFeedback?.reviewer.name}
              <Box component={'span'} color={'text.secondary'}>
                {' ∙ '}
                {submission.clientFeedback?.reviewer.role},{' '}
                {submission.clientFeedback?.reviewer.company}
              </Box>
            </Typography>
            <Link
              color="text.secondary"
              href={`mailto:${submission.clientFeedback?.reviewer.email}`}
              fontSize="0.688rem"
            >
              {submission.clientFeedback?.reviewer.email}
            </Link>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};
