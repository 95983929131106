import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Alert,
  Paper,
  InputAdornment,
} from '@mui/material';
import {
  CustomButton,
  JobOpeningField,
  PositionHiringManagerField,
} from '@components';
import { ReactComponent as GSheetIcon } from '@assets/google-sheet-icon.svg';
import { ReactComponent as GDocsIcon } from '@assets/google-docs-icon.svg';
import { ReactComponent as JiraIcon } from '@assets/jira-icon.svg';

import { optionsSelectors } from '@redux/options';
import { authSelectors } from '@redux/auth';

import { JobOpeningFormValues, JobOpeningFormik } from '@types';
import {
  PositionType,
  JobOpeningSourceOptions,
  TTSubmissionValue,
  InterviewStepValueValue,
} from '@constants';
import {
  checkUserCanUpsertCandidateSpreadsheet,
  checkUserCanEditJobOpeningMaxCalls,
} from '@utils';
import { useAppSelector } from '@redux/hooks';

import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';

export const HiringProcessDetails: React.FC<{
  formik: JobOpeningFormik;
}> = ({ formik }) => {
  const [addMoreInterviewer, setAddMoreInterviewer] = useState<boolean>(false);
  const [addTIComments, setTIComments] = useState<boolean>(false);

  const profile = useAppSelector(authSelectors.getProfileData);

  const { data: clients } = useAppSelector(
    optionsSelectors.getJobOpeningClients,
  );

  const currentSourceOption = JobOpeningSourceOptions.find(
    (source) => source.value === formik.values.Position_type,
  );
  const isBillablePosition =
    currentSourceOption?.positionType === PositionType.BILLABLE;

  const isCustomClientName =
    formik.values.Client_Name_New &&
    !clients.some((client) => client.name === formik.values.Client_Name_New);
  const isVisibleMaxCalls = checkUserCanEditJobOpeningMaxCalls(profile);
  const showCandidateSpreadsheetField =
    checkUserCanUpsertCandidateSpreadsheet(profile);

  return (
    <Paper elevation={1} sx={{ mb: '1px', pt: 4, pb: 6, paddingX: 5 }}>
      <Typography variant="h2" mb={4}>
        Hiring process
      </Typography>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} md={6}>
          <PositionHiringManagerField
            name="Hiring_Manager_email"
            value={formik.values.Hiring_Manager_email}
            required
            onChange={(hm) => {
              formik.setValues({
                ...formik.values,
                Hiring_Manager_email: hm?.email_id || null,
                Hiring_M: hm?.fullName || null,
              });
            }}
            onBlur={() => formik.setFieldTouched('Hiring_Manager_email')}
            touched={formik.touched.Hiring_Manager_email}
            error={formik.errors.Hiring_Manager_email}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <JobOpeningField
            field="Responsible_Recruiter"
            value={formik.values.Responsible_Recruiter}
            touched={formik.touched.Responsible_Recruiter}
            error={formik.errors.Responsible_Recruiter}
            onChange={(val) =>
              formik.setFieldValue('Responsible_Recruiter', val || null)
            }
            onBlur={() => formik.setFieldTouched('Responsible_Recruiter')}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <JobOpeningField
            field="Interview_process"
            value={formik.values.Interview_process}
            touched={formik.touched.Interview_process}
            error={formik.errors.Interview_process}
            onChange={(val) =>
              formik.setFieldValue('Interview_process', val || null)
            }
            onBlur={() => formik.setFieldTouched('Interview_process')}
          />
        </Grid>
      </Grid>
      <Typography variant="h4" mb={2}>
        Screening
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <JobOpeningField
            field="Test_Task"
            value={formik.values.Test_Task}
            required
            touched={formik.touched.Test_Task}
            error={formik.errors.Test_Task}
            onChange={(val) => formik.setFieldValue('Test_Task', val || null)}
            onBlur={() => formik.setFieldTouched('Test_Task')}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <JobOpeningField
            field="TT_in_Submission"
            value={formik.values.TT_in_Submission}
            touched={formik.touched.TT_in_Submission}
            error={formik.errors.TT_in_Submission}
            onChange={(val) => {
              const interviewSteps =
                val === TTSubmissionValue.Yes
                  ? [
                      ...(formik.values.Multi_Select_13 || []),
                      InterviewStepValueValue.IncludeTTTinSubmission,
                    ]
                  : formik.values.Multi_Select_13?.filter(
                      (e) =>
                        e !== InterviewStepValueValue.IncludeTTTinSubmission,
                    ) || null;

              formik.setValues({
                ...formik.values,
                TT_in_Submission: val,
                Multi_Select_13: interviewSteps?.length ? interviewSteps : null,
              });
            }}
            onBlur={() => formik.setFieldTouched('TT_in_Submission')}
          />
        </Grid>
      </Grid>

      <Grid container mt={2} mb={2}>
        <JobOpeningField
          field="asyncVideoInterviewQuestions"
          value={formik.values.asyncVideoInterviewQuestions}
          onChange={(val) =>
            formik.setFieldValue('asyncVideoInterviewQuestions', val || null)
          }
        />
      </Grid>

      <Grid container mt={2}>
        {isVisibleMaxCalls && (
          <Grid item xs={12} md={2}>
            <JobOpeningField
              field="maxCalls"
              value={formik.values.maxCalls}
              touched={formik.touched.maxCalls}
              error={formik.errors.maxCalls}
              onChange={(val) => formik.setFieldValue('maxCalls', val)}
              onBlur={() => formik.setFieldTouched('maxCalls')}
            />
          </Grid>
        )}
      </Grid>

      <Typography variant="h4" mb={2} mt={2}>
        Technical interview
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2} mb="20px">
            <Grid item xs={12} md={12}>
              <JobOpeningField
                field="Project_interview_type"
                value={formik.values.Project_interview_type}
                touched={formik.touched.Project_interview_type}
                error={formik.errors.Project_interview_type}
                onChange={(val) =>
                  formik.setFieldValue('Project_interview_type', val || null)
                }
                onBlur={() => formik.setFieldTouched('Project_interview_type')}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <JobOpeningField
                field="Technical_Reviewer"
                value={formik.values.Technical_Reviewer}
                touched={formik.touched.Technical_Reviewer}
                error={formik.errors.Technical_Reviewer}
                onChange={(val) =>
                  formik.setFieldValue('Technical_Reviewer', val || null)
                }
                onBlur={() => formik.setFieldTouched('Technical_Reviewer')}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <JobOpeningField
                field="Additional_Technical_Interviewer"
                value={formik.values.Additional_Technical_Interviewer}
                touched={formik.touched.Additional_Technical_Interviewer}
                error={formik.errors.Additional_Technical_Interviewer}
                onChange={(val) =>
                  formik.setFieldValue(
                    'Additional_Technical_Interviewer',
                    val || null,
                  )
                }
                onBlur={() =>
                  formik.setFieldTouched('Additional_Technical_Interviewer')
                }
              />
            </Grid>
          </Grid>
          {addMoreInterviewer && (
            <Grid item md={12} mb={'20px'}>
              <JobOpeningField
                field="Technical_Interviewers_Not_in_list"
                value={formik.values.Technical_Interviewers_Not_in_list}
                touched={formik.touched.Technical_Interviewers_Not_in_list}
                error={formik.errors.Technical_Interviewers_Not_in_list}
                onChange={(val) =>
                  formik.setFieldValue(
                    'Technical_Interviewers_Not_in_list',
                    val || null,
                  )
                }
                onBlur={() =>
                  formik.setFieldTouched('Technical_Interviewers_Not_in_list')
                }
              />
            </Grid>
          )}

          <Grid container mt={2} mb={2}>
            <Grid item mr={1}>
              {!addMoreInterviewer && (
                <CustomButton
                  size="small"
                  color="secondary"
                  label="Add more interviewers"
                  startIcon={<GroupAddOutlinedIcon fontSize="small" />}
                  onClick={() => setAddMoreInterviewer(true)}
                />
              )}
            </Grid>
            <Grid item>
              {!addTIComments && (
                <CustomButton
                  size="small"
                  color="secondary"
                  label="Add TI comments"
                  startIcon={<EventNoteOutlinedIcon fontSize="small" />}
                  onClick={() => setTIComments(true)}
                />
              )}
            </Grid>
          </Grid>

          {addTIComments && (
            <Grid item md={12} mb={'20px'}>
              <Box display="flex" flexDirection="column" gap={2}>
                <JobOpeningField
                  field="Other_Comments"
                  value={formik.values.Other_Comments}
                  touched={formik.touched.Other_Comments}
                  error={formik.errors.Other_Comments}
                  onChange={(val) =>
                    formik.setFieldValue('Other_Comments', val || null)
                  }
                  onBlur={() => formik.setFieldTouched('Other_Comments')}
                />
              </Box>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <JobOpeningField
            field="Multi_Select_13"
            value={formik.values.Multi_Select_13}
            touched={formik.touched.Multi_Select_13}
            error={formik.errors.Multi_Select_13}
            onChange={(val) => {
              formik.setValues({
                ...formik.values,
                Multi_Select_13: val,
                TT_in_Submission: val.includes(
                  InterviewStepValueValue.IncludeTTTinSubmission,
                )
                  ? TTSubmissionValue.Yes
                  : TTSubmissionValue.No,
              });
            }}
            onBlur={() => formik.setFieldTouched('Multi_Select_13')}
          />
        </Grid>
      </Grid>

      {isBillablePosition && (
        <>
          <Typography variant="h4" mb={2}>
            Client
          </Typography>
          {isCustomClientName && (
            <Alert variant="standard" severity="warning" sx={{ mb: 2 }}>
              Please note that a request to create a new client (
              {formik.values.Client_Name_New}) will be sent. While the request
              is being processed, this position will be created with
              "AgileEngine" Client Name.
            </Alert>
          )}
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} md={12}>
              <Grid container spacing={2} mb="20px">
                <Grid item xs={12} md={6}>
                  <JobOpeningField
                    field="Client_Name_New"
                    value={formik.values.Client_Name_New}
                    required
                    touched={formik.touched.Client_Name_New}
                    error={formik.errors.Client_Name_New}
                    onChange={(val) => {
                      const client = clients.find((c) => c.name === val);

                      const updateObj: Partial<JobOpeningFormValues> = {
                        Client_Name_New: val,
                        Subteam_Name: null,
                      };

                      client?.deliveryManager &&
                        (updateObj.Delivery_Manager = client.deliveryManager);
                      client?.pdm && (updateObj.PDM = client.pdm);

                      formik.setValues({
                        ...formik.values,
                        ...updateObj,
                      });
                    }}
                    onBlur={() => formik.setFieldTouched('Client_Name_New')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <JobOpeningField
                    field="Subteam_Name"
                    disabled={!formik.values.Client_Name_New}
                    value={formik.values.Subteam_Name}
                    touched={formik.touched.Subteam_Name}
                    error={formik.errors.Subteam_Name}
                    onChange={(val) =>
                      formik.setFieldValue('Subteam_Name', val || null)
                    }
                    onBlur={() => formik.setFieldTouched('Subteam_Name')}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mb="20px">
                <Grid item xs={12} md={6}>
                  <JobOpeningField
                    field="PDM"
                    value={formik.values.PDM}
                    touched={formik.touched.PDM}
                    error={formik.errors.PDM}
                    required
                    onChange={(val) => formik.setFieldValue('PDM', val || null)}
                    onBlur={() => formik.setFieldTouched('PDM')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <JobOpeningField
                    field="Delivery_Manager"
                    value={formik.values.Delivery_Manager}
                    touched={formik.touched.Delivery_Manager}
                    error={formik.errors.Delivery_Manager}
                    required
                    onChange={(val) =>
                      formik.setFieldValue('Delivery_Manager', val || null)
                    }
                    onBlur={() => formik.setFieldTouched('Delivery_Manager')}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} mb={'20px'}>
                <Grid item xs={12} md={12}>
                  <JobOpeningField
                    field="Client_submission_emails"
                    value={formik.values.Client_submission_emails}
                    touched={formik.touched.Client_submission_emails}
                    error={formik.errors.Client_submission_emails}
                    onChange={(val) =>
                      formik.setFieldValue(
                        'Client_submission_emails',
                        val || null,
                      )
                    }
                    onBlur={() =>
                      formik.setFieldTouched('Client_submission_emails')
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <Typography variant="h4" mb={2} mt={2}>
        Additional optional links
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <JobOpeningField
            field="TI_Requirements_Link"
            value={formik.values.TI_Requirements_Link}
            touched={formik.touched.TI_Requirements_Link}
            error={formik.errors.TI_Requirements_Link}
            onChange={(val) =>
              formik.setFieldValue('TI_Requirements_Link', val || null)
            }
            onBlur={() => formik.setFieldTouched('TI_Requirements_Link')}
            startAdornment={
              <InputAdornment position="start">
                <GDocsIcon />
              </InputAdornment>
            }
          />
        </Grid>
        {showCandidateSpreadsheetField && (
          <Grid item xs={12} md={12}>
            <JobOpeningField
              field="Candidates_spreadsheet"
              value={formik.values.Candidates_spreadsheet}
              touched={formik.touched.Candidates_spreadsheet}
              error={formik.errors.Candidates_spreadsheet}
              onChange={(val) =>
                formik.setFieldValue('Candidates_spreadsheet', val || null)
              }
              onBlur={() => formik.setFieldTouched('Candidates_spreadsheet')}
              startAdornment={
                <InputAdornment position="start">
                  <GSheetIcon />
                </InputAdornment>
              }
            />
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <JobOpeningField
            field="Jira_url"
            value={formik.values.Jira_url}
            touched={formik.touched.Jira_url}
            error={formik.errors.Jira_url}
            onChange={(val) => formik.setFieldValue('Jira_url', val || null)}
            onBlur={() => formik.setFieldTouched('Jira_url')}
            startAdornment={
              <InputAdornment position="start">
                <JiraIcon />
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
