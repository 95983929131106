const entityReportsApi = '/entity-reports';

export const getEntityReportsAPIPath = () => `${entityReportsApi}`;
export const getEntityReportDetailsAPIPath = (id: string) =>
  `${entityReportsApi}/${id}`;
export const getEntityReportDataAPIPath = (id: string) =>
  `${entityReportsApi}/${id}/data`;
export const createEntityReportAPIPath = () => `${entityReportsApi}`;
export const updateEntityReportAPIPath = (id: string) =>
  `${entityReportsApi}/${id}`;
export const deleteEntityReportAPIPath = (id: string) =>
  `${entityReportsApi}/${id}`;
export const exportEntityReportAPIPath = (id: string) =>
  `${entityReportsApi}/${id}/export`;
