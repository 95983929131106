import axios from 'axios';
import { format } from 'date-fns';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { entityReportsStoreKey } from './entityReports.const';
import { apiEndpoints, SnackbarType, EntityReportSource } from '@constants';
import {
  IDWHJobOpeningRaw,
  IDWHCandidateRaw,
  IEntityReport,
  CreateEntityReportPayload,
  UpdateEntityReportPayload,
} from '@types';
import { transformCandidate, transformJobOpening } from '@utils';
import { setSnackbar } from '@redux/snackbar';

export const fetchEntityReports = createAsyncThunk(
  `${entityReportsStoreKey}/fetchEntityReports`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<IEntityReport[]>(
        apiEndpoints.getEntityReportsAPIPath(),
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchEntityReportDetails = createAsyncThunk(
  `${entityReportsStoreKey}/fetchEntityReportDetails`,
  async (entityReportId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<IEntityReport>(
        apiEndpoints.getEntityReportDetailsAPIPath(entityReportId),
      );

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchEntityReportData = createAsyncThunk(
  `${entityReportsStoreKey}/fetchEntityReportData`,
  async (entityReportId: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.get<{
        meta: IEntityReport;
        data: (IDWHJobOpeningRaw | IDWHCandidateRaw)[];
      }>(apiEndpoints.getEntityReportDataAPIPath(entityReportId));

      const entityReportDetails = response.data.meta;

      const data =
        entityReportDetails.source === EntityReportSource.Candidates
          ? (response.data.data as IDWHCandidateRaw[]).map(transformCandidate)
          : entityReportDetails.source === EntityReportSource.Positions
          ? (response.data.data as IDWHJobOpeningRaw[]).map(transformJobOpening)
          : null;

      return { entityReportDetails, data };
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: error?.response?.data?.message || 'Something went wrong',
        }),
      );

      return rejectWithValue(error.response.data);
    }
  },
);

export const createEntityReport = createAsyncThunk(
  `${entityReportsStoreKey}/createEntityReport`,
  async (payload: CreateEntityReportPayload, { dispatch }) => {
    try {
      const response = await axios.post(
        apiEndpoints.createEntityReportAPIPath(),
        payload,
      );

      dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Report has been created successfully.`,
        }),
      );

      return response.data;
    } catch (error: any) {
      dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Report hasn't been created successfully. ${
            error?.response?.data?.message || 'Something went wrong'
          }`,
        }),
      );

      throw error;
    }
  },
);

export const updateEntityReport = createAsyncThunk(
  `${entityReportsStoreKey}/updateEntityReport`,
  async (
    {
      entityReportId,
      ...payload
    }: { entityReportId: string } & UpdateEntityReportPayload,
    { dispatch },
  ) => {
    try {
      const response = await axios.patch(
        apiEndpoints.updateEntityReportAPIPath(entityReportId),
        payload,
      );

      dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Report has been updated successfully.`,
        }),
      );

      return response.data;
    } catch (error: any) {
      dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Report hasn't been updated successfully. ${
            error?.response?.data?.message || 'Something went wrong'
          }`,
        }),
      );

      throw error;
    }
  },
);

export const deleteEntityReport = createAsyncThunk(
  `${entityReportsStoreKey}/deleteEntityReport`,
  async (entityReportId: string, { dispatch }) => {
    try {
      const response = await axios.delete(
        apiEndpoints.deleteEntityReportAPIPath(entityReportId),
      );

      dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Report has been deleted successfully.`,
        }),
      );

      return response.data;
    } catch (error: any) {
      dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Report hasn't been deleted successfully. ${
            error?.response?.data?.message || 'Something went wrong'
          }`,
        }),
      );

      throw error;
    }
  },
);

export const exportEntityReport = createAsyncThunk(
  `${entityReportsStoreKey}/exportEntityReport`,
  async (
    {
      entityReportId,
      entityReportName,
    }: { entityReportId: string; entityReportName: string },
    { dispatch },
  ) => {
    try {
      const response = await axios.get(
        apiEndpoints.exportEntityReportAPIPath(entityReportId),
        {
          responseType: 'blob',
        },
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${entityReportName}-${format(new Date(), 'yyyy-MM-dd')}.csv`,
      );
      document.body.appendChild(link);
      link.click();
      link.remove();

      dispatch(
        setSnackbar({
          type: SnackbarType.Info,
          message: `Report has been exported successfully.`,
        }),
      );

      return response.data;
    } catch (error: any) {
      dispatch(
        setSnackbar({
          type: SnackbarType.Error,
          message: `Report hasn't been exported successfully. ${
            error?.response?.data?.message || 'Something went wrong'
          }`,
        }),
      );

      throw error;
    }
  },
);
