import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import {
  EntityReportFormColumnStep,
  EntityReportFormFilterStep,
  EntityReportFormGroupingStep,
  EntityReportFormLayout,
  EntityReportFormSaveStep,
  EntityReportFormTypeStep,
} from '@components';

import {
  createEntityReport,
  entityReportsSelectors,
  fetchEntityReportDetails,
  updateEntityReport,
} from '@redux/entityReports';

import {
  generateCreateEntityReportPayload,
  generateInitialValues,
  generateUpdateEntityReportPayload,
  getEntityReportsSteps,
  isStatusFailed,
  isStatusForbidden,
  isStatusLoading,
} from '@utils';
import { EntityReportFormValues } from '@types';
import { AppRoutes, EntityReportStep } from '@constants';

import { entityReportFormValidationSchema } from './validationSchema';

export const EntityReportFormPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { apiStatus, data: entityReport } = useAppSelector(
    entityReportsSelectors.getEntityReportDetailsAPIData,
  );
  const isPerformingAction = useAppSelector(
    entityReportsSelectors.getEntityReportIsPerformingAction,
  );

  const { entityReportId } = useParams();

  useEffect(() => {
    if (entityReportId) {
      dispatch(fetchEntityReportDetails(entityReportId));
    }
  }, [entityReportId]);

  const formik = useFormik<EntityReportFormValues>({
    initialValues: generateInitialValues(entityReport),
    validationSchema: entityReportFormValidationSchema,
    onSubmit: async (values) => {
      let res;
      if (entityReportId) {
        res = await dispatch(
          updateEntityReport({
            entityReportId,
            ...generateUpdateEntityReportPayload(values, entityReport!),
          }),
        );
      } else {
        res = await dispatch(
          createEntityReport(generateCreateEntityReportPayload(values)),
        );
      }

      if (!res?.type?.includes('rejected')) {
        if (location.state && location.state.from) {
          navigate(location.state.from);
        } else {
          navigate(`/${AppRoutes.ENTITY_REPORTS}`);
        }
      }
    },
    validateOnMount: true,
    enableReinitialize: true,
  });

  const stepsOrder = getEntityReportsSteps(formik.values.type);
  const onPreviousStepClick = useCallback(() => {
    const previousStepIdx =
      stepsOrder.findIndex((e) => e === formik.values.step) - 1;

    formik.setFieldValue('step', stepsOrder[previousStepIdx]);
  }, [formik.values.step, stepsOrder]);

  const onNextStepClick = useCallback(() => {
    const nextStepIdx =
      stepsOrder.findIndex((e) => e === formik.values.step) + 1;

    formik.setFieldValue('step', stepsOrder[nextStepIdx]);
  }, [formik.values.step, stepsOrder]);

  const onSelectStepClick = useCallback(
    (selectedStep: EntityReportStep) => {
      formik.setFieldValue('step', selectedStep);
    },
    [formik.values.step],
  );

  const onSubmitClick = useCallback(() => {
    formik.handleSubmit();
  }, [formik.values.step]);

  const isLoading = isStatusLoading(apiStatus);
  const isLoadingFailed = isStatusFailed(apiStatus);
  const isForbidden = isStatusForbidden(apiStatus);

  return (
    <EntityReportFormLayout
      entityReport={entityReport}
      type={formik.values.type}
      activeStep={formik.values.step}
      errors={formik.errors}
      isLoading={isLoading}
      isLoadingFailed={isLoadingFailed}
      isForbidden={isForbidden}
      isPerformingAction={isPerformingAction}
      onPreviousClick={onPreviousStepClick}
      onNextClick={onNextStepClick}
      onSelectStep={onSelectStepClick}
      onSubmitClick={onSubmitClick}
    >
      {formik.values.step === EntityReportStep.Type && (
        <EntityReportFormTypeStep formik={formik} />
      )}
      {formik.values.step === EntityReportStep.Columns && (
        <EntityReportFormColumnStep formik={formik} />
      )}
      {formik.values.step === EntityReportStep.Grouping && (
        <EntityReportFormGroupingStep formik={formik} />
      )}
      {formik.values.step === EntityReportStep.Filters && (
        <EntityReportFormFilterStep formik={formik} />
      )}
      {formik.values.step === EntityReportStep.Save && (
        <EntityReportFormSaveStep formik={formik} />
      )}
    </EntityReportFormLayout>
  );
};
