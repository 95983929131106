import React, { useEffect, useMemo, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { AutocompleteTextfield, CustomButton } from '@components/common';
import PhotoSizeSelectSmallOutlinedIcon from '@mui/icons-material/PhotoSizeSelectSmallOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { isStatusIdle } from '@utils';
import { IAsyncVideoInterviewQuestion, IQuestionVI } from '@types';
import { fetchVideoInterviewListAll, optionsSelectors } from '@redux/options';
import { VideoInterviewQuestions } from './components/VIQuestions';
import { VISpecialization } from '@constants';

const OptionalVISpecializations = [
  VISpecialization.DISABLED,
  'Developer',
  'AQA',
  'QA',
  'Data Engineer',
  'DevOps',
  'UX Researcher',
  'UI/UX Designer',
];
export const PositionVideoInterviewSelect: React.FC<{
  value: IAsyncVideoInterviewQuestion;
  name?: string;
  required?: boolean | undefined;
  touched?: boolean;
  error?: string | undefined;
  onChange: (newValue: IAsyncVideoInterviewQuestion) => void;
  onBlur?: () => void;
}> = ({ value, name, error, touched, required, onChange, onBlur }) => {
  const dispatch = useAppDispatch();

  const [isQuestionVisible, setIsQuestionVisible] = useState(false);
  const [isEditFormVisible, setIsEditFormVisible] = useState(false);

  const { apiStatus, data } = useAppSelector(
    optionsSelectors.getAllVideoInterviewList,
  );

  useEffect(() => {
    if (isStatusIdle(apiStatus)) {
      dispatch(fetchVideoInterviewListAll());
    }
  }, [apiStatus, dispatch]);

  const handleSpecializationChange = (specialization: string) => {
    const selectedVI = data.find((e) => e.specialization === specialization);

    onChange({
      specialization,
      isCustom: false,
      isRequired: !OptionalVISpecializations.includes(specialization),
      questions: selectedVI?.questions || [],
    });
  };

  const handleQuestionsChange = (questions: IQuestionVI[]) => {
    const initialQuestions =
      data.find((e) => e.specialization === value?.specialization)?.questions ||
      [];

    onChange({
      ...value,
      isCustom: JSON.stringify(questions) !== JSON.stringify(initialQuestions),
      questions: questions,
    });
  };

  const toggleQuestionVisibility = () => {
    setIsQuestionVisible((prev) => !prev);
  };

  const specializationOptions = useMemo(() => {
    return [VISpecialization.DISABLED, ...data.map((e) => e.specialization)];
  }, [data]);

  const defaultQuestions = useMemo(() => {
    if (
      !value?.specialization ||
      value?.specialization === VISpecialization.DISABLED
    )
      return null;

    return (
      data.find((vi) => vi.specialization === value.specialization)
        ?.questions || []
    );
  }, [data, value]);

  return (
    <>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} md={6}>
          <AutocompleteTextfield
            name={name || 'Specialization'}
            label={'Async video interview questions'}
            options={specializationOptions}
            value={value?.specialization || VISpecialization.DISABLED}
            required={required}
            disableClearable
            onChange={(_, val: string) => {
              handleSpecializationChange(val);
            }}
            getOptionLabel={(option) =>
              value.isCustom && option === value.specialization
                ? `${option} (Custom)`
                : option
            }
            onBlur={onBlur}
            fullWidth
            error={touched && !!error}
            helperText={touched ? error : undefined}
          />
        </Grid>

        {!isEditFormVisible &&
          value?.specialization !== VISpecialization.DISABLED && (
            <>
              <Grid item>
                <CustomButton
                  size="small"
                  color="secondary"
                  label={
                    isQuestionVisible ? 'Hide Questions' : 'Show Questions'
                  }
                  startIcon={
                    isQuestionVisible ? (
                      <PhotoSizeSelectSmallOutlinedIcon fontSize="small" />
                    ) : (
                      <ListOutlinedIcon fontSize="small" />
                    )
                  }
                  onClick={toggleQuestionVisibility}
                />
              </Grid>
              <Grid item>
                <CustomButton
                  size="small"
                  color="secondary"
                  label="Customize"
                  startIcon={<EditOutlinedIcon fontSize="small" />}
                  onClick={() => setIsEditFormVisible(true)}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value?.isRequired}
                      onChange={(_, val) =>
                        onChange({ ...value, isRequired: val })
                      }
                    />
                  }
                  label="Required"
                />
              </Grid>
            </>
          )}
      </Grid>

      {value?.specialization !== VISpecialization.DISABLED &&
        defaultQuestions && (
          <Box mt={2} sx={{ width: '100%' }}>
            <VideoInterviewQuestions
              questions={value.questions}
              onCancel={() => {
                setIsEditFormVisible(false);
                setIsQuestionVisible(true);
              }}
              onChange={handleQuestionsChange}
              isQuestionVisible={isQuestionVisible}
              isEditFormVisible={isEditFormVisible}
            />
          </Box>
        )}
    </>
  );
};
