import { useEffect } from 'react';
import { keyBy, mapValues } from 'lodash';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import { AutocompleteTextfield } from '@components';
import { Box, Typography } from '@mui/material';

import { fetchEntityReportColumns, optionsSelectors } from '@redux/options';

import { EntityReportFormik } from '@types';
import { isStatusIdle } from '@utils';

export const EntityReportFormGroupingStep: React.FC<{
  formik: EntityReportFormik;
}> = ({ formik }) => {
  const dispatch = useAppDispatch();

  const { apiStatus, data } = useAppSelector(
    optionsSelectors.getEntityReportColumnsAPIData,
  );

  useEffect(() => {
    if (isStatusIdle(apiStatus)) {
      dispatch(fetchEntityReportColumns());
    }
  }, []);

  const groupingError =
    (formik.touched.groupBy || !!formik.submitCount) && formik.errors.groupBy
      ? formik.errors.groupBy
      : null;
  const sortingError =
    (formik.touched.sortBy || !!formik.submitCount) && formik.errors.sortBy
      ? formik.errors.sortBy
      : null;

  const labelsByField = mapValues(
    keyBy(
      data.filter((e) => e.source === formik.values.source!),
      'dwhField',
    ),
    (column) => column.label,
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      pt={5}
      pb={2}
      width={450}
    >
      <Typography variant="h3">View</Typography>
      <AutocompleteTextfield
        name="groupBy"
        label="Group entries by"
        getOptionLabel={(option) => labelsByField[option] || option}
        options={formik.values.columns || []}
        value={formik.values.groupBy}
        required
        onChange={(_, val) => formik.setFieldValue('groupBy', val || null)}
        onBlur={() => formik.setFieldTouched('groupBy')}
        fullWidth
        error={!!groupingError}
        helperText={groupingError ? groupingError : undefined}
      />
      <AutocompleteTextfield
        name="sortBy"
        label="Sorting"
        getOptionLabel={(option) => labelsByField[option] || option}
        options={formik.values.columns || []}
        value={formik.values.sortBy}
        onChange={(_, val) => formik.setFieldValue('sortBy', val || null)}
        onBlur={() => formik.setFieldTouched('sortBy')}
        fullWidth
        error={!!sortingError}
        helperText={sortingError ? sortingError : undefined}
      />
    </Box>
  );
};
