import { isArray } from 'lodash';

import { Chip, TableCell } from '@mui/material';

import { EntityReportColumnType } from '@constants';
import {
  IDWHCandidate,
  IDWHJobOpening,
  IEntityMainTableHeaderCell,
} from '@types';
import { formatDate } from '@utils';

export const EntityReportDataTableRow: React.FC<{
  tableHeaders: IEntityMainTableHeaderCell[];
  data: IDWHCandidate | IDWHJobOpening;
  groupBy: string | null;
}> = ({ tableHeaders, data, groupBy }) => {
  return (
    <>
      {tableHeaders.map((header) => {
        if (groupBy === header.value) return null;

        const value = header.value
          ? // @ts-ignore
            data[header.value]
          : null;

        return (
          <TableCell key={`${data.id}-${header.label}-${value}`}>
            {isArray(value)
              ? value.map((e) => <Chip label={e} />)
              : header.type === EntityReportColumnType.Date
              ? formatDate(value)
              : value}
          </TableCell>
        );
      })}
    </>
  );
};
