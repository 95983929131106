import { entityReportsStoreKey } from './entityReports.const';
import { IEntityReportsSliceState } from './entityReports.types';

interface IState {
  [entityReportsStoreKey]: IEntityReportsSliceState;
}

const selectEntityReportsState = (state: IState) => {
  return state[entityReportsStoreKey];
};

const getEntityReportsAPIData = (state: IState) => {
  return selectEntityReportsState(state).reports;
};

const getEntityReportDetailsAPIData = (state: IState) => {
  return selectEntityReportsState(state).reportDetails;
};

const getEntityReportDataAPIData = (state: IState) => {
  return selectEntityReportsState(state).data;
};

const getEntityReportIsPerformingAction = (state: IState) => {
  return selectEntityReportsState(state).isPerformingAction;
};

export const entityReportsSelectors = {
  getEntityReportsAPIData,
  getEntityReportDetailsAPIData,
  getEntityReportDataAPIData,
  getEntityReportIsPerformingAction,
};
