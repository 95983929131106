import { useCallback, useState } from 'react';

import { Flex, TextButton } from '@components';
import {
  Box,
  Checkbox,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { TransferListOption } from '@types';
import { groupBy, mapValues, values } from 'lodash';
import { ColumnLayout } from './components/ColumnLayout';

export const TransferList: React.FC<{
  options: TransferListOption[];
  selectedOptions: TransferListOption[];
  onChange: (selectedOptions: TransferListOption[]) => void;
}> = ({ options, selectedOptions, onChange }) => {
  const [checkedOptions, setCheckedOptions] = useState<TransferListOption[]>(
    [],
  );

  const onCheckOption = ({
    option,
    isChecked,
  }: {
    option: TransferListOption;
    isChecked: boolean;
  }) =>
    setCheckedOptions(
      isChecked
        ? [...checkedOptions, option]
        : checkedOptions.filter((e) => e.value !== option.value),
    );
  const onAddOptionClick = useCallback(() => {
    if (!checkedOptions.length) return;
    onChange([...selectedOptions, ...checkedOptions]);
    setCheckedOptions([]);
  }, [onChange, selectedOptions, options, checkedOptions]);
  const onAllOptionsClick = useCallback(() => {
    onChange([...options]);
    setCheckedOptions([]);
  }, [onChange, options]);

  const onRemoveOptionClick = useCallback(
    (option: TransferListOption) => {
      onChange(selectedOptions.filter((e) => e.value !== option.value));
    },
    [onChange, selectedOptions, options],
  );
  const onRemoveAllOptionsClick = useCallback(() => {
    onChange([]);
  }, [onChange, options]);

  const groupedAllOptions = groupBy(options, 'group');

  return (
    <Flex height="100%" mx={-3}>
      <ColumnLayout
        side="left"
        actions={
          <>
            <TextButton
              onClick={onAddOptionClick}
              variant="secondary"
              sx={{ fontSize: '13px' }}
              startIcon={<AddBoxOutlinedIcon fontSize="small" />}
            >
              Add selected
            </TextButton>
            <TextButton
              onClick={onAllOptionsClick}
              variant="secondary"
              sx={{ fontSize: '13px' }}
              startIcon={<AddToPhotosOutlinedIcon fontSize="small" />}
            >
              Add all
            </TextButton>
          </>
        }
        contentComponent={values(
          mapValues(groupedAllOptions, (groupOptions, groupName) => (
            <Box key={groupName}>
              <Typography
                variant="body2"
                color="text.secondary"
                padding="16px 16px 4px 18px"
              >
                {groupName}
              </Typography>
              <List dense component="div" role="list" sx={{ paddingTop: 0 }}>
                {groupOptions.map((option) => {
                  const labelId = `transfer-list-item-${option.value}-label`;
                  const isDisabled = selectedOptions.some(
                    (e) => e.value === option.value,
                  );
                  const isChecked =
                    checkedOptions.some((e) => e.value === option.value) ||
                    isDisabled;

                  return (
                    <ListItemButton
                      key={option.value}
                      role="listitem"
                      onClick={() =>
                        onCheckOption({ option, isChecked: !isChecked })
                      }
                      disabled={isDisabled}
                    >
                      <ListItemIcon sx={{ minWidth: '32px' }}>
                        <Checkbox
                          checked={isChecked}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          size="small"
                          sx={{ padding: 0 }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={option.label} />
                    </ListItemButton>
                  );
                })}
              </List>
            </Box>
          )),
        )}
      />
      <ColumnLayout
        side="right"
        actions={
          <>
            <TextButton
              onClick={onRemoveAllOptionsClick}
              variant="secondary"
              sx={{ fontSize: '13px', ml: 'auto' }}
              startIcon={<ClearAllIcon fontSize="small" />}
            >
              Remove all
            </TextButton>
          </>
        }
        contentComponent={
          <Flex flexDirection="column" padding={2}>
            {selectedOptions.map((option) => (
              <Flex justifyContent="space-between" key={option.value}>
                <Typography>{option.label}</Typography>
                <IconButton
                  onClick={() => onRemoveOptionClick(option)}
                  size="small"
                >
                  <DeleteOutlineIcon color="secondary" fontSize="inherit" />
                </IconButton>
              </Flex>
            ))}
          </Flex>
        }
      />
    </Flex>
  );
};
