import { Box, ListItemButton, ListItemText, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface IProps {
  Icon: React.ElementType;
  isActive: boolean;
  label: string;
  itemsNumber?: number;
  href?: string;
  isOpen: boolean;
  onClick?: () => void;
  newTabLink?: string;
  ExternalLinkIcon?: boolean;
  isExpandableMenu?: boolean;
}

export const LinkDrawer: React.FC<IProps> = ({
  Icon,
  isActive,
  label,
  itemsNumber,
  href,
  isOpen,
  onClick,
  newTabLink,
  ExternalLinkIcon,
  isExpandableMenu,
}) => {
  const navigate = useNavigate();

  const handleOpenNewTab = () => {
    window.open(newTabLink, '_blank');
  };

  return (
    <Tooltip title={!isOpen && label} placement="right">
      <ListItemButton
        autoFocus={isActive}
        onClick={() => {
          if (isActive) return;
          isExpandableMenu
            ? onClick && onClick()
            : isOpen && onClick && onClick();
          href && navigate(href);
          newTabLink && handleOpenNewTab();
        }}
        sx={{
          backgroundColor: isActive ? 'highlight.accent' : '',
          color: isActive ? 'brand.accent' : 'text.primary',
          borderRadius: '4px',
          '&:hover': {
            backgroundColor: 'highlight.sectionLight',
            color: 'brand.accent',
            '& svg': {
              color: 'brand.accent',
            },
          },
          marginRight: isOpen ? '0' : '8px',
          padding: '11px 18px',
          '@media (max-height: 1080px)': {
            padding: '5px 18px',

            '& svg': {
              fontSize: '20px',
            },
          },
        }}
      >
        {Icon && <Icon color={isActive ? '' : 'action'} />}

        <ListItemText
          primary={ExternalLinkIcon ? <>{label} ↗</> : label}
          sx={{ marginLeft: '19px', opacity: isOpen ? '100%' : '0' }}
        />

        {itemsNumber && (
          <Box
            sx={{
              borderRadius: '16px',
              backgroundColor: 'brand.accent',
              color: 'button.secondary.frontActive',
              padding: '5px 7px',
              fontSize: '0.687rem',
            }}
          >
            {itemsNumber}
          </Box>
        )}
      </ListItemButton>
    </Tooltip>
  );
};
