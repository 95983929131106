import { SyntheticEvent, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import { Box, createFilterOptions, TextField, Typography } from '@mui/material';
import { AutocompleteTextfield, EmployeeEmailAutocomplete } from '@components';

import { fetchEntityReportFolders, optionsSelectors } from '@redux/options';

import { EntityReportFormik } from '@types';
import { isStatusIdle } from '@utils';

export const EntityReportFormSaveStep: React.FC<{
  formik: EntityReportFormik;
}> = ({ formik }) => {
  const dispatch = useAppDispatch();

  const { apiStatus, data: folders } = useAppSelector(
    optionsSelectors.getEntityReportFoldersAPIData,
  );

  useEffect(() => {
    if (isStatusIdle(apiStatus)) {
      dispatch(fetchEntityReportFolders());
    }
  }, []);

  const nameError =
    (formik.touched.name || !!formik.submitCount) && formik.errors.name
      ? formik.errors.name
      : null;
  const folderError =
    (formik.touched.folder || !!formik.submitCount) && formik.errors.folder
      ? formik.errors.folder
      : null;
  const descriptionError =
    (formik.touched.description || !!formik.submitCount) &&
    formik.errors.description
      ? formik.errors.description
      : null;

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      pt={5}
      pb={2}
      width={450}
    >
      <Typography variant="h3" mb={2}>
        Save and share
      </Typography>
      <TextField
        name="name"
        label="Report title"
        variant="outlined"
        fullWidth
        value={formik.values.name || ''}
        required
        onChange={(e) => formik.setFieldValue('name', e.target.value || null)}
        onBlur={() => formik.setFieldTouched('name')}
        helperText={nameError ? nameError : undefined}
        error={!!nameError}
      />
      <AutocompleteTextfield
        name="folder"
        label="Folder"
        value={formik.values.folder}
        required
        fullWidth
        freeSolo
        filterSelectedOptions
        clearOnBlur
        selectOnFocus
        options={folders || []}
        filterOptions={(options, params) => {
          const filtered = createFilterOptions<string>()(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option);
          if (inputValue !== '' && !isExisting) {
            filtered.push(`Add "${inputValue}"`);
          }

          return filtered;
        }}
        onChange={(e: SyntheticEvent, newValue: string | null) => {
          const newFolderValue = newValue?.includes('Add "')
            ? /Add "(.*)"/gi.exec(newValue)?.[1]
            : newValue;

          formik.setFieldValue('folder', newFolderValue);
        }}
        onBlur={() => formik.setFieldTouched('folder')}
        error={!!folderError}
        helperText={folderError ? folderError : undefined}
      />
      <EmployeeEmailAutocomplete
        label="Share with"
        name="shareWith"
        value={formik.values.shareWith || []}
        onChange={(val) => formik.setFieldValue('shareWith', val)}
        fullWidth
        multiple
      />
      <TextField
        name="description"
        label="Description"
        placeholder="Add your notes for others to get the reports purpose"
        variant="outlined"
        fullWidth
        value={formik.values.description || ''}
        onChange={(e) =>
          formik.setFieldValue('description', e.target.value || null)
        }
        onBlur={() => formik.setFieldTouched('description')}
        helperText={descriptionError ? descriptionError : undefined}
        error={!!descriptionError}
        multiline
        minRows={3}
      />
    </Box>
  );
};
