import { Chip } from '@mui/material';

import { CandidateVideoInterview } from '@types';
import { UserVideoInterviewStatus } from '@constants';

export const VideoInterviewStatus: React.FC<{
  videoInterviewData: CandidateVideoInterview;
}> = ({ videoInterviewData }) => {
  if (!videoInterviewData.status) return null;

  const viStatus =
    videoInterviewData.status === UserVideoInterviewStatus.TODO
      ? 'Sent'
      : videoInterviewData.status === UserVideoInterviewStatus.FINISHED &&
        videoInterviewData.videoInterview?.questions.length ===
          videoInterviewData.records.length
      ? 'Finished'
      : videoInterviewData.status === UserVideoInterviewStatus.FINISHED &&
        videoInterviewData.videoInterview?.questions.length !==
          videoInterviewData.records.length
      ? 'Finished (incomplete)'
      : videoInterviewData.status;

  return <Chip label={`Status: ${viStatus}`} />;
};
