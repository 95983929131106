import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { orderBy } from 'lodash';

import { EntityMainTable } from '@components';
import { EntityReportDataTableRow } from './components/TableRow';
import { EmptyReportState } from './components/EmptyReportState';

import {
  ApiStatus,
  AppRoutes,
  EntityReportSource,
  TableHeaderCellAligment,
} from '@constants';
import {
  IDWHCandidate,
  IDWHJobOpening,
  IEntityMainTableHeaderCell,
  IEntityReport,
  IEntityReportColumn,
  OrderDirection,
} from '@types';
import { isTruthy, removeUndefinedAttributes } from '@utils';

export const EntityReportDataTable: React.FC<{
  entityReport: IEntityReport | null;
  columns: IEntityReportColumn[];
  data: IDWHCandidate[] | IDWHJobOpening[] | null;
  apiStatus: ApiStatus;
  errorMessage?: string | null;
}> = ({ entityReport, columns, data, apiStatus, errorMessage }) => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const sortBy = searchParams.get('sortBy');
  const sortDirection = searchParams.get('order') as OrderDirection;
  const groupBy = searchParams.get('groupBy');

  useEffect(() => {
    setSearchParams((currFilters) => {
      const nextQueryParams: Record<string, any> = {};

      currFilters.forEach((value, key) => {
        nextQueryParams[key] = ([] as string[])
          .concat(nextQueryParams[key] as string[])
          .concat(value as string)
          .filter(isTruthy);
      });

      return removeUndefinedAttributes({
        sortBy: entityReport?.sortBy || undefined,
        order: entityReport?.sortBy ? OrderDirection.ASC : undefined,
        groupBy: entityReport?.groupBy || undefined,
        ...nextQueryParams,
      });
    });
  }, [entityReport?.sortBy, entityReport?.groupBy]);

  const tableHeaders: IEntityMainTableHeaderCell[] = entityReport?.columns
    ? entityReport?.columns
        .map((column) => {
          const columnMeta = columns.find((e) => e.dwhField === column);

          if (!columnMeta) return null;

          return {
            label: columnMeta.label,
            value: columnMeta.dwhField,
            width: 300,
            align: TableHeaderCellAligment.Left,
            isSortable: true,
            type: columnMeta.type,
          };
        })
        .filter(isTruthy)
    : [];

  const sortOptions = useMemo(
    () => ({
      direction: sortDirection,
      sortBy: sortBy,
      onSort: ({
        field,
        direction,
      }: {
        field: string;
        direction: OrderDirection;
      }) =>
        setSearchParams((currFilters) => {
          const nextQueryParams: Record<string, any> = {};

          currFilters.forEach((value, key) => {
            nextQueryParams[key] = ([] as string[])
              .concat(nextQueryParams[key] as string[])
              .concat(value as string)
              .filter(isTruthy);
          });

          return {
            ...nextQueryParams,
            sortBy: field,
            order: direction,
          };
        }),
    }),
    [sortDirection, sortBy],
  );

  const renderRowComponent = useCallback(
    (rowData: IDWHCandidate | IDWHJobOpening) => (
      <EntityReportDataTableRow
        tableHeaders={tableHeaders}
        data={rowData}
        groupBy={groupBy}
      />
    ),
    [tableHeaders, groupBy],
  );

  const onRowClick = useCallback(
    (id: string) => {
      navigate(
        `/${
          entityReport?.source === EntityReportSource.Candidates
            ? AppRoutes.CANDIDATES
            : AppRoutes.JOB_OPENINGS
        }/${id}`,
        {
          state: { from: `${location.pathname}${location.search}` },
        },
      );
    },
    [entityReport?.source],
  );

  const tableData = useMemo(
    () =>
      sortBy && sortDirection
        ? (orderBy(data, sortBy, sortDirection) as
            | IDWHCandidate[]
            | IDWHJobOpening[])
        : data,
    [sortBy, sortDirection, data],
  );

  return (
    <EntityMainTable<IDWHCandidate | IDWHJobOpening>
      tableHeaders={tableHeaders}
      apiStatus={apiStatus}
      errorMessage={errorMessage}
      data={tableData}
      groupBy={groupBy}
      showGroupedAmount
      sortOptions={sortOptions}
      EmptyStateComponent={<EmptyReportState />}
      renderRowComponent={renderRowComponent}
      onRowClick={onRowClick}
    />
  );
};
