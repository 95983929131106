import { useEffect } from 'react';
import { useAppDispatch } from '@redux/hooks';
import { useFormik } from 'formik';
import { isEqual } from 'lodash';

import { Box, Button, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { EmployeeEmailAutocomplete, Flex } from '@components';

import { updateEntityReport } from '@redux/entityReports';

export const EntityReportShareModal: React.FC<{
  entityReportId: string | null;
  isOpen: boolean;
  defaultValue: string[];
  isPerformingAction: boolean;
  closeModal: () => void;
}> = ({
  entityReportId,
  isOpen,
  defaultValue,
  isPerformingAction,
  closeModal,
}) => {
  const dispatch = useAppDispatch();

  const formik = useFormik<{ shareWith: string[] }>({
    initialValues: {
      shareWith: defaultValue,
    },
    onSubmit: async (values) => {
      if (
        !entityReportId ||
        isEqual([...defaultValue].sort(), [...values.shareWith].sort())
      )
        return;

      await dispatch(
        updateEntityReport({ entityReportId, shareWith: values.shareWith }),
      );
      closeModal();
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!isOpen) formik.resetForm();
  }, [isOpen]);

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Box
        sx={{ width: '100%', height: '100%' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          sx={{
            maxWidth: '511px',
            background: '#FFF',
            borderRadius: '4px',
            boxShadow:
              '0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px -7px rgba(0, 0, 0, 0.20)',
          }}
        >
          <Flex
            px={3}
            py={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h1">Share report</Typography>
            <CloseIcon
              onClick={closeModal}
              sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
            />
          </Flex>
          <Flex px={3} py={2} gap={3} flexDirection="column">
            <EmployeeEmailAutocomplete
              label="Share with"
              name="shareWith"
              value={formik.values.shareWith || []}
              onChange={(val) => formik.setFieldValue('shareWith', val)}
              fullWidth
              multiple
            />
          </Flex>
          <Flex px={3} py={2} gap={1} justifyContent="end">
            <Button
              variant="contained"
              onClick={closeModal}
              sx={{
                height: '40px',
                backgroundColor: '#3F8CFF14',
                color: '#42A5F5',
                textTransform: 'none',
                display: 'flex',
                width: '80px',
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                formik.handleSubmit();
              }}
              sx={{
                height: '40px',
                backgroundColor: 'text.link',
                textTransform: 'none',
                display: 'flex',
                width: '80px',
              }}
              disabled={!formik.dirty || !formik.isValid || isPerformingAction}
            >
              Save
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Modal>
  );
};
