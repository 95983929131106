import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { entityReportsStoreKey } from './entityReports.const';
import { IEntityReportsSliceState } from './entityReports.types';
import { ApiStatus } from '@constants';
import {
  createEntityReport,
  deleteEntityReport,
  exportEntityReport,
  fetchEntityReportData,
  fetchEntityReportDetails,
  fetchEntityReports,
  updateEntityReport,
} from './entityReports.thunks';

const initialState: IEntityReportsSliceState = {
  reports: {
    apiStatus: ApiStatus.IDLE,
    data: null,
  },
  reportDetails: {
    apiStatus: ApiStatus.IDLE,
    data: null,
  },
  data: {
    report: null,
    apiStatus: ApiStatus.IDLE,
    data: null,
    errorMessage: null,
  },
  isPerformingAction: false,
};

export const entityReportsSlice = createSlice({
  name: entityReportsStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEntityReports.pending, (state) => {
        state.reports.apiStatus = ApiStatus.LOADING;
      })
      .addCase(fetchEntityReports.fulfilled, (state, action) => {
        state.reports.data = action.payload;
        state.reports.apiStatus = ApiStatus.COMPLETE;
      })
      .addCase(
        fetchEntityReports.rejected,
        (state, action: PayloadAction<any>) => {
          state.reports.apiStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
        },
      )
      .addCase(fetchEntityReportDetails.pending, (state) => {
        state.reportDetails.apiStatus = ApiStatus.LOADING;
      })
      .addCase(fetchEntityReportDetails.fulfilled, (state, action) => {
        state.reportDetails.data = action.payload;
        state.reportDetails.apiStatus = ApiStatus.COMPLETE;
      })
      .addCase(
        fetchEntityReportDetails.rejected,
        (state, action: PayloadAction<any>) => {
          state.reportDetails.apiStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
        },
      )
      .addCase(fetchEntityReportData.pending, (state) => {
        state.data = { ...initialState.data, apiStatus: ApiStatus.LOADING };
      })
      .addCase(fetchEntityReportData.fulfilled, (state, action) => {
        state.data.report = action.payload.entityReportDetails;
        state.data.data = action.payload.data;
        state.data.apiStatus = ApiStatus.COMPLETE;
      })
      .addCase(
        fetchEntityReportData.rejected,
        (state, action: PayloadAction<any, any, any>) => {
          state.data.apiStatus =
            action?.payload?.statusCode === 403
              ? ApiStatus.FORBIDDEN
              : ApiStatus.FAILED;
          state.data.errorMessage =
            action?.payload?.message || 'Something went wrong';
        },
      )
      .addCase(exportEntityReport.pending, (state) => {
        state.isPerformingAction = true;
      })
      .addCase(exportEntityReport.fulfilled, (state) => {
        state.isPerformingAction = false;
      })
      .addCase(exportEntityReport.rejected, (state) => {
        state.isPerformingAction = false;
      })
      .addMatcher(
        isAnyOf(
          createEntityReport.pending,
          updateEntityReport.pending,
          deleteEntityReport.pending,
        ),
        (state) => {
          state.isPerformingAction = true;
        },
      )
      .addMatcher(
        isAnyOf(
          createEntityReport.fulfilled,
          updateEntityReport.fulfilled,
          deleteEntityReport.fulfilled,
        ),
        () => initialState,
      )
      .addMatcher(
        isAnyOf(
          createEntityReport.rejected,
          updateEntityReport.rejected,
          deleteEntityReport.rejected,
        ),
        (state) => {
          state.isPerformingAction = false;
        },
      );
  },
});

export const entityReportsSliceReducer = entityReportsSlice.reducer;
