import { Box, Paper, Typography } from '@mui/material';
import puzzleSrc from '@assets/puzzle.png';

export const EmptyReportState: React.FC = () => {
  return (
    <Box
      component={Paper}
      sx={{
        margin: '0 .5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <img src={puzzleSrc} alt="puzzle" />
      <Typography variant="h2" m="0.5rem">
        There is no data for this report
      </Typography>
      <Typography variant="body1" color="text.secondary" mb={5}>
        Please try different set of filters
      </Typography>
    </Box>
  );
};
