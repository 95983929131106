import { ReactNode } from 'react';
import { FormikErrors } from 'formik';

import { Box, Typography } from '@mui/material';
import { ForbiddenView, LoaderSkeleton } from '@components';
import { EntityReportFormHeader } from './components/EntityReportFormHeader';
import { EntityReportFormFooter } from './components/EntityReportFormFooter';

import { EntityReportFormValues, IEntityReport } from '@types';
import {
  EntityReportStep,
  EntityReportType,
  LOADER_SKELETON_TYPES,
} from '@constants';

export const EntityReportFormLayout: React.FC<{
  entityReport: IEntityReport | null;
  type: EntityReportType | null;
  activeStep: EntityReportStep;
  errors: FormikErrors<EntityReportFormValues>;
  isLoading: boolean;
  isLoadingFailed: boolean;
  isForbidden: boolean;
  isPerformingAction: boolean;
  onPreviousClick: () => void;
  onNextClick: () => void;
  onSelectStep: (selectedStep: EntityReportStep) => void;
  onSubmitClick: () => void;
  children: ReactNode;
}> = ({
  entityReport,
  type,
  activeStep,
  errors,
  isLoading,
  isLoadingFailed,
  isForbidden,
  isPerformingAction,
  onPreviousClick,
  onNextClick,
  onSelectStep,
  onSubmitClick,
  children,
}) => {
  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        overflowY: 'scroll',
        minHeight: '100vh',
      })}
    >
      <EntityReportFormHeader
        entityReport={entityReport}
        activeStep={activeStep}
        type={type}
        errors={errors}
        onSelectStep={onSelectStep}
      />
      <Box display="flex" justifyContent="center" flex={1}>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          position="relative"
          pb={3}
          gap={1}
          sx={{
            paddingX: {
              xs: '1rem',
              md: '0px',
            },
          }}
        >
          {isLoading && (
            <Box height="calc(100vh - 100px)" width="100%">
              <LoaderSkeleton type={LOADER_SKELETON_TYPES.ROWS} />
            </Box>
          )}
          {isLoadingFailed && (
            <Typography variant="h3" textAlign="center" mt={4}>
              Report not found
            </Typography>
          )}
          {isForbidden && <ForbiddenView />}
          {!isLoading && !isLoadingFailed && !isForbidden && (
            <>
              <Box width="100%">{children}</Box>
              <Box width="100%">
                <EntityReportFormFooter
                  activeStep={activeStep}
                  type={type}
                  isPerformingAction={isPerformingAction}
                  onPreviousClick={onPreviousClick}
                  onNextClick={onNextClick}
                  onSubmitClick={onSubmitClick}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
