import { useState } from 'react';
import { keyBy } from 'lodash';

import { Box, Stack, Typography } from '@mui/material';
import ReactPlayer from 'react-player';

import { formatDateWithTimezone } from '@utils';
import { CandidateVideoInterview } from '@types';
import { apiEndpoints } from '@constants';

export const VideoInterviewQuestions: React.FC<{
  videoInterviewData: CandidateVideoInterview;
}> = ({ videoInterviewData }) => {
  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(1);

  const questions = videoInterviewData.videoInterview?.questions;

  const recordByQuestionNumber = keyBy(
    videoInterviewData.records,
    'questionNumber',
  );

  const selectedRecordUrl = selectedQuestion
    ? recordByQuestionNumber[selectedQuestion]?.fileName
    : recordByQuestionNumber[1]?.fileName;

  if (!questions?.length) return null;

  return (
    <Stack direction={'column'} gap={'1rem'}>
      <Box
        sx={{
          borderRadius: '0.25rem',
          backgroundColor: 'common.black',
          paddingTop: '56.25%',
          position: 'relative',

          '& .react-player': {
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
          },
          '& video': {
            borderRadius: '0.25rem',
          },
        }}
      >
        {selectedRecordUrl ? (
          <ReactPlayer
            controls
            playing
            className="react-player"
            width="100%"
            height="100%"
            url={
              selectedRecordUrl
                ? apiEndpoints.videoInterviewFileUrl(selectedRecordUrl)
                : undefined
            }
          />
        ) : (
          <Typography variant="body2" color="text.invertedSecondary">
            Please select a question to play
          </Typography>
        )}
      </Box>
      <Stack gap={'0.25rem'}>
        <Typography variant="h5" sx={{ mx: { xs: '1rem', sm: 0 } }}>
          {videoInterviewData.videoInterview?.specialization}
        </Typography>
        {questions.map((question) => {
          const hasRecord = !!recordByQuestionNumber[question.questionNumber];
          return (
            <Box
              key={question.questionNumber}
              onClick={() =>
                hasRecord && setSelectedQuestion(question.questionNumber)
              }
              sx={(theme) => ({
                padding: '0.5rem 1rem 1rem 1rem',
                cursor: hasRecord ? 'pointer' : 'default',
                borderRadius: '0.25rem',
                background:
                  selectedQuestion === question.questionNumber
                    ? theme.palette.highlight.accent
                    : hasRecord
                    ? theme.palette.highlight.actionable
                    : theme.palette.highlight.neutral,
              })}
            >
              <Typography variant="body2" color="text.secondary">
                Question {question.questionNumber}
              </Typography>
              <Typography variant="body1">{question.title}</Typography>
            </Box>
          );
        })}
        {!!videoInterviewData.resultDate && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mx: { xs: '1rem', sm: 0 } }}
          >
            Recorded on
            {formatDateWithTimezone(videoInterviewData.resultDate)}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
