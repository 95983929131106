import { Checkbox, FormControlLabel } from '@mui/material';

import { JobOpeningFieldsLabels, TTSubmissionValue } from '@constants';

export const PositionTTSubmissionField: React.FC<{
  value: string | null;
  onChange: (newValue: any) => void;
}> = ({ value, onChange }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={TTSubmissionValue.Yes === value}
          onChange={(_, val) =>
            onChange(val ? TTSubmissionValue.Yes : TTSubmissionValue.No)
          }
        />
      }
      label={JobOpeningFieldsLabels.TT_in_Submission}
    />
  );
};
