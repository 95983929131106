import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { useParams } from 'react-router-dom';

import { EntityReportDataTable, EntityReportShareModal } from '@components';
import { EntityReportDataHeader } from './components/Header';

import {
  entityReportsSelectors,
  fetchEntityReportData,
} from '@redux/entityReports';
import { fetchEntityReportColumns, optionsSelectors } from '@redux/options';

import { isStatusIdle, isStatusLoading } from '@utils';
import { Box } from '@mui/material';

export const EntityReportDataPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { entityReportId } = useParams();

  const {
    apiStatus: entityReportDataAPIStatus,
    data: entityReportData,
    report: entityReport,
    errorMessage,
  } = useAppSelector(entityReportsSelectors.getEntityReportDataAPIData);
  const { apiStatus: columnsAPIStatus, data: columns } = useAppSelector(
    optionsSelectors.getEntityReportColumnsAPIData,
  );
  const isPerformingAction = useAppSelector(
    entityReportsSelectors.getEntityReportIsPerformingAction,
  );

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const isPageLoading =
    isStatusLoading(entityReportDataAPIStatus) ||
    isStatusLoading(columnsAPIStatus);

  useEffect(() => {
    if (
      entityReportId &&
      entityReport?.id !== entityReportId &&
      !isStatusLoading(entityReportDataAPIStatus)
    )
      dispatch(fetchEntityReportData(entityReportId));
  }, [entityReportId, entityReport?.id]);

  useEffect(() => {
    if (isStatusIdle(columnsAPIStatus)) dispatch(fetchEntityReportColumns());
  }, [columnsAPIStatus]);

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.background.backgroundPrimary,
        minHeight: '100vh',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      <EntityReportDataHeader
        entityReportId={entityReportId!}
        entityReportName={entityReport?.name || ''}
        recordsAmount={entityReportData?.length || 0}
        isLoading={isPageLoading}
        isPerformingAction={isPerformingAction}
        onShareReportClick={() => setIsShareModalOpen(true)}
      />
      <EntityReportDataTable
        entityReport={entityReport}
        columns={columns}
        data={entityReportData}
        apiStatus={entityReportDataAPIStatus}
        errorMessage={errorMessage}
      />
      <EntityReportShareModal
        entityReportId={entityReportId!}
        isOpen={isShareModalOpen}
        defaultValue={entityReport?.shareWith?.map((e) => e.email) || []}
        isPerformingAction={isPerformingAction}
        closeModal={() => setIsShareModalOpen(false)}
      />
    </Box>
  );
};
